import React, { useEffect, useState } from "react";
import axios from "axios";
import PrimaryLayout from "./components/primaryLayout";
import { useNavigate } from "react-router-dom";
import { getOktaToken } from "./common/token";
import { REACT_APP_OKTA_URL, OKTA_TOKEN } from "./constants";
import { Spin } from "antd";

function App() {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getLoggedInUser = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${REACT_APP_OKTA_URL}/authnz/getUserDetails`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      localStorage.setItem("user", res.data.Username);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getOktaToken();
    const oktaToken = localStorage.getItem("token");
    setToken(oktaToken);
    if (!oktaToken) {
      window.location.replace(`${REACT_APP_OKTA_URL}/okta/RedirectLink`);
    }
  }, [navigate]);

  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <Spin tip="Loading">
            <div className="content"></div>
          </Spin>
        </div>
      ) : (
        <div>{token && <PrimaryLayout />}</div>
      )}
    </>
  );
}

export default App;
