import React, { useState, useEffect } from "react";
import { Input, Button, Select, Row, Col } from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import "../../styles/addRules.scss";
import {
  REACT_APP_API_SEGMENTS,
  REACT_APP_API_RULES,
  REACT_APP_API_PERMISSION,
  REACT_APP_API_GOOGLEWORKSPACE,
  OKTA_TOKEN,
} from "../../constants";
import { logOut } from "../../common/logout";

const { Option } = Select;
const linuxOptions = [
  { key: "allowedOrigin", value: "Allowed Origin" },
  { key: "allowedHomeFolder", value: "Allowed Home Folder" },
  {
    key: "allowedHomeFolderPermission",
    value: "Allowed Home Folder Permission",
  },
  { key: "blackListedApps", value: "Black Listed Apps" },
  { key: "allowedApps", value: "Allowed Apps" },
  { key: "sudo", value: "Sudo Access" },
];

const AddRulesV2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ruleData = searchParams.get("ruleData");
  const [ruleType, setRuleType] = useState("APP");
  const [endpoints, setEndpoints] = useState([]);
  const [appName, setAppName] = useState();
  const [ruleName, setRuleName] = useState();
  const [selectAttributes, setSelectAttributes] = useState();
  const [selectOptionsMap, setSelectOptionsMap] = useState();
  const [allowedGroups, setAllowedGroups] = useState();
  const [allGroups, setAllGroups] = useState();
  const [appList, setAppList] = useState();
  const [roleList, setRoleList] = useState();
  const [role, setRole] = useState();
  
  const [thenDropdownSets, setThenDropdownSets] = useState([
    {
      id: 1,
      firstSelectedValue: "Allowed Origin",
      option: "=",
      secondSelectedValue: "",
    },
  ]);
  const [dropdownSets, setDropdownSets] = useState([
    {
      id: 1,
      firstSelectedValue:
        selectAttributes && selectAttributes.length > 0 && selectAttributes[0],
      attributeLabel: "",
      option: "=",
      secondSelectedValue:
        selectOptionsMap &&
        selectOptionsMap.length > 0 &&
        selectOptionsMap[Object.keys(selectOptionsMap)[0]][0],
    },
  ]);
  const [rolesDropDowns, setRolesDropDowns] = useState([
    {
      id: 1,
      permission: null,
      group: allowedGroups,
      role: role,
    },
  ]);

  const selectOptions = [`=`];

  useEffect(() => {
    fetchAttributes();
    fetchGroups();
    fetchApps();
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchValues();
  }, [selectAttributes]);

  const fetchGroups = async (val) => {
    let data = {
      appname: val ? val.value : appName,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_GOOGLEWORKSPACE}/getGroups`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.groups.map((data) => ({
        value: `${data.GroupName} (${data.Description})`,
        ...data,
      }));
      setAllGroups(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRoles = async (val) => {
    let data = {
      appname: val ? val.value : appName,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_GOOGLEWORKSPACE}/getRolesFromDb`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.roles;
      setRoleList(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApps = async () => {
    let data = {
      appname: "",
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listappnames`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      let tempData = response.data.data;
      // tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setAppList(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAttributes = async () => {
    let data = {
      pageNo: 1,
      pageSize: 100,
      filters: {
        attributeType: ruleType,
      },
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/masterattributeslist/getmasterattributeslist`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      // tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setSelectAttributes(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchValues = async (val) => {
    const optionsMap = {};
    if (selectAttributes && selectAttributes.length > 0) {
      for (const item of selectAttributes) {
        const { attribute_label } = item;
        let data = {
          pageNo: 1,
          pageSize: 100,
          filters: {
            attributeName: attribute_label,
          },
        };
        try {
          const response = await axios.post(
            `${REACT_APP_API_SEGMENTS}/masterattributeslist/getmasterattributeslistvalues`,
            data,
            {
              headers: {
                Accept: "*/*",
                "User-Agent": "Thunder Client (https://www.thunderclient.com)",
                "Content-Type": "application/json",
              },
            }
          );
          let tempData = response.data.data.attribute_value;
          optionsMap[attribute_label] = tempData;
          setSelectOptionsMap(optionsMap);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const fetchData = async (val) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_PERMISSION}/getFileChanges`,
        { foldername: val },
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      // setCommits(tempData);
    } catch (error) {
      // setCommits([]);
      console.error("Error fetching data:", error);
    }
  };

  const fetchEndpoints = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/listofendpoints`,
        {},
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setEndpoints(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEndpoints();
    const handleOutsideClick = (event) => {
      const dropdownContainer = event.target.closest(".dropdowns-wrapper");
      if (!dropdownContainer) {
        setDropdownSets((prevDropdowns) =>
          prevDropdowns.map((set) => ({ ...set, open: false }))
        );
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Parse the ruleData parameter to extract the data (assuming it's JSON)
    try {
      const data = JSON.parse(ruleData);

      // Process the fetched data and update dropdownSets accordingly
      const newDropdownSets = data.map((rule, index) => ({
        id: index + 1,
        firstSelectedValue: rule.key,
        option: "=",
        secondSelectedValue: rule.value,
      }));

      setDropdownSets(newDropdownSets);
    } catch (error) {
      console.error("Error parsing ruleData:", error);
    }
  }, [ruleData]);

  useEffect(() => {
    const selectedPairs = dropdownSets.map(
      ({ firstSelectedValue, secondSelectedValue }) => ({
        key: firstSelectedValue,
        value: secondSelectedValue,
      })
    );
  }, [dropdownSets]);

  const handleThenFirstSelectChange = (value, index) => {
    const updatedThenDropdownSets = [...thenDropdownSets];
    updatedThenDropdownSets[index].firstSelectedValue = value;
    updatedThenDropdownSets[index].secondSelectedValue = "";
    setThenDropdownSets(updatedThenDropdownSets);
  };

  const handleDeleteThenDropdown = (index) => {
    const updatedThenDropdownSets = [...thenDropdownSets];
    updatedThenDropdownSets.splice(index, 1);
    setThenDropdownSets(updatedThenDropdownSets);
  };

  const createNewThenDropdown = () => {
    const newThenDropdownSet = {
      id: thenDropdownSets.length + 1,
      firstSelectedValue: "Allowed Origin",
      option: "=",
      secondSelectedValue: "ACTIVATED",
    };
    setThenDropdownSets((prevDropdowns) => [
      ...prevDropdowns,
      newThenDropdownSet,
    ]);
  };

  const handleFirstSelectChange = (value, index, option) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].firstSelectedValue = value;
    updatedDropdownSets[index].attributeLabel = option.children;
    updatedDropdownSets[index].secondSelectedValue = "";
    setDropdownSets(updatedDropdownSets);
  };

  const handleOptionChange = (value, index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].option = value;
    setDropdownSets(updatedDropdownSets);
  };

  const handleSecondSelectChange = (value, index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].secondSelectedValue = value;
    setDropdownSets(updatedDropdownSets);
  };

  const handleDeleteDropdown = (index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets.splice(index, 1);
    setDropdownSets(updatedDropdownSets);
  };

  const createNewDropdown = () => {
    const newDropdownSet = {
      id: 1,
      firstSelectedValue:
        selectAttributes && selectAttributes.length > 0 && selectAttributes[0],
      option: "=",
      secondSelectedValue:
        selectOptionsMap &&
        selectOptionsMap.length > 0 &&
        selectOptionsMap[Object.keys(selectOptionsMap)[0]][0],
    };
    // console.log(newDropdownSet);
    setDropdownSets((prevDropdowns) => [...prevDropdowns, newDropdownSet]);
  };

  const createNewRoleDropDown = () => {
    // console.log({ allowedGroups, role });

    const newDropdown = {
      id: rolesDropDowns?.length + 1,
      group: null,
      role: null,
    };

    setRolesDropDowns((preDropdowns) => [...preDropdowns, newDropdown]);
  };

  const handleDeleteRoleDropdown = (index) => {
    const updatedDropdownSets = [...rolesDropDowns];
    updatedDropdownSets.splice(index, 1);
    setRolesDropDowns(updatedDropdownSets);
  };

  const handleAddRule = async (e) => {
    let ruleCondition = dropdownSets.map((item) => {
      return {
        attribute: item.firstSelectedValue,
        operator: item.option,
        value: item.secondSelectedValue,
      };
    });

    let allowedGroupsData = [];
    let deniedGroupsData = [];

    rolesDropDowns.forEach((set) => {
      if (set.permission === "allow") {
        const groupData = {};
        groupData[set.group] = set.role;
        allowedGroupsData.push(groupData);
      } else {
        deniedGroupsData.push(set.group);
      }
    });

    // console.log("allowed:", allowedGroupsData);
    // console.log("denied:", deniedGroupsData);

    let data = {
      rules_condition: ruleCondition,
      permission: {
        apiVersion: "v1",
        kind: "permission",
        metadata: {
          name: "Google Workspace",
          app: appName && appName.value,
          allowed_groups: allowedGroupsData,
          denied_groups: deniedGroupsData,
        },
      },
      rule_name: ruleName,
      created_by: "muthu-durai",
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/AddRule`,
        data,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      navigate("/rules");
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error:", error);
    }
  };

  return (
    <div className="rule-container">
      <h1 className="title">Add Rules</h1>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <h5 className="search-label">Enter Rule Name</h5>
              <Input
                label="Enter Rule Name"
                placeholder="Enter rule name"
                value={ruleName}
                onChange={(e) => {
                  setRuleName(e.target.value);
                  fetchData(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <h5 className="search-label">Select App or endpoint</h5>
              <Select
                className="search-component"
                showSearch
                defaultValue={ruleType}
                onChange={(v) => setRuleType(v)}
              >
                <Option value="APP">App</Option>
                <Option value="ENDPOINT">Endpoint</Option>
              </Select>
            </Col>
            {ruleType === "APP" ? (
              <Col span={12} style={{ marginBottom: "2rem" }}>
                <h5 className="search-label">App</h5>
                <Select
                  className="search-component"
                  showSearch
                  placeholder="Search app name"
                  labelInValue={true}
                  value={appName}
                  optionFilterProp="children"
                  onChange={(val) => {
                    setAppName(val);
                    fetchGroups(val);
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {appList &&
                    appList.length > 0 &&
                    appList.map((app) => (
                      <Option value={app.appName} key={app.appName}>
                        {app.AppName}
                      </Option>
                    ))}
                </Select>
              </Col>
            ) : (
              <Col span={12} style={{ marginBottom: "2rem" }}>
                <h5 className="search-label">Endpoint</h5>
                <Select
                  className="search-component"
                  showSearch
                  placeholder="Search endpoint name"
                  labelInValue={true}
                  optionFilterProp="children"
                  onChange={(v) => setRuleType(v)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={endpoints}
                />
              </Col>
            )}
          </Row>
          <Row></Row>
          <Row
            style={{
              padding: "2rem 2rem",
              background: "#dbeeff",
              paddingRight: "1rem",
              borderRadius: "8px",
            }}
          >
            {dropdownSets.map((set, index) => (
              <Col span={24}>
                <Row className="add-dropdown-row" gutter={8}>
                  <Col span={1} style={{ marginTop: "0.3rem" }}>
                    IF
                  </Col>
                  <Col span={9}>
                    <Select
                      value={set.firstSelectedValue}
                      onChange={(value, option) =>
                        handleFirstSelectChange(value, index, option)
                      }
                      className="rules-dropdown"
                    >
                      {selectAttributes &&
                        selectAttributes.length > 0 &&
                        selectAttributes.map((attribute, index) => (
                          <Option
                            key={attribute.attribute_name}
                            value={attribute.attribute_name}
                          >
                            {attribute.attribute_label}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Select
                      value={set.option}
                      onChange={(value) => handleOptionChange(value, index)}
                      className="rules-dropdown"
                    >
                      {selectOptions.map((value) => (
                        <Option key={value} value={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={9}>
                    {set.firstSelectedValue !== null ? (
                      <Select
                        value={set.secondSelectedValue}
                        onChange={(value) =>
                          handleSecondSelectChange(value, index)
                        }
                        className="rules-dropdown"
                      >
                        {selectOptionsMap &&
                          selectOptionsMap[set.attributeLabel] &&
                          selectOptionsMap[set.attributeLabel].map((value) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        value={set.secondSelectedValue}
                        onChange={(value) =>
                          handleSecondSelectChange(value, index)
                        }
                        className="rules-dropdown"
                      >
                        {selectOptionsMap[
                          Object.keys(selectOptionsMap)[0]
                        ][0].map((value) => (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    {index > 0 && (
                      <DeleteOutlined
                        onClick={() => handleDeleteDropdown(index)}
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginTop: "0.3rem",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
            <Col
              span={23}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <Button
                type="default"
                onClick={createNewDropdown}
                className="rules-dropdown"
              >
                Add
              </Button>
            </Col>
          </Row>

          <h4 style={{ textAlign: "center" }}>Then</h4>
          {ruleType == "APP" ? (
            <Row
              style={{
                padding: "2rem 2rem",
                background: "#dbeeff",
                paddingRight: "1rem",
                borderRadius: "8px",
              }}
            >
              {rolesDropDowns.map((set, index) => (
                <Col span={24}>
                  <Row gutter={8} style={{ marginBottom: "1rem" }}>
                    <Col span={7}>
                      <p style={{ marginTop: "0rem", marginBottom: "0.5rem" }}>
                        Select Action
                      </p>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Action"
                        onChange={(value) => {
                          const updatedRolesDropDowns = [...rolesDropDowns];
                          updatedRolesDropDowns[index].permission = value;
                          setRolesDropDowns(updatedRolesDropDowns);
                        }}
                      >
                        <Option value="allow">Allow</Option>
                        <Option value="deny">Deny</Option>
                      </Select>
                    </Col>

                    {set.permission === "allow" ? (
                      <>
                        <Col span={7}>
                          <p
                            style={{
                              marginTop: "0rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Select group name
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Groups"
                            value={set.allowedGroups}
                            className="allowed-groups"
                            onChange={(value) => {
                              const updatedRolesDropDowns = [...rolesDropDowns];
                              updatedRolesDropDowns[index].group = value;
                              setRolesDropDowns(updatedRolesDropDowns);
                            }}
                          >
                            {allGroups &&
                              allGroups.length > 0 &&
                              allGroups?.map((group) => (
                                <Option value={group.GroupName}>
                                  {group.value}
                                </Option>
                              ))}
                          </Select>
                        </Col>

                        <Col span={7}>
                          <p
                            style={{
                              marginTop: "0rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Select role
                          </p>
                          <Select
                            style={{ width: "100%" }}
                            className="allowed-groups"
                            placeholder="Select Role"
                            value={set.role}
                            onChange={(value) => {
                              const updatedRolesDropDowns = [...rolesDropDowns];
                              updatedRolesDropDowns[index].role = value;
                              setRolesDropDowns(updatedRolesDropDowns);
                            }}
                          >
                            {/* {roleList &&
                              roleList.length > 0 &&
                              roleList.map((role) => (
                                <Option value={role.RoleName}>
                                  {role.RoleName}
                                </Option>
                              ))} */}
                              <Option value="Member">
                                Member
                              </Option>
                          </Select>
                        </Col>
                      </>
                    ) : (
                      <Col span={7}>
                        <p
                          style={{
                            marginTop: "0rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Select group name
                        </p>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Groups"
                          value={set.allowedGroups}
                          className="allowed-groups"
                          onChange={(value) => {
                            const updatedRolesDropDowns = [...rolesDropDowns];
                            updatedRolesDropDowns[index].group = value;
                            setRolesDropDowns(updatedRolesDropDowns);
                          }}
                        >
                          {allGroups &&
                            allGroups.length > 0 &&
                            allGroups?.map((group) => (
                              <Option value={group.GroupName}>
                                {group.value}
                              </Option>
                            ))}
                             
                        </Select>
                      </Col>
                    )}

                    {rolesDropDowns.length > 1 && (
                      <Col span={3} style={{ textAlign: "center" }}>
                        {index > 0 && (
                          <DeleteOutlined
                            onClick={() => handleDeleteRoleDropdown(index)}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              marginTop: "2rem",
                            }}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
              ))}

              <Col
                span={23}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                }}
              >
                <Button
                  type="default"
                  onClick={createNewRoleDropDown}
                  className="rules-dropdown"
                >
                  Add
                </Button>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                padding: "2rem 2rem",
                background: "#dbeeff",
                paddingRight: "1rem",
                borderRadius: "8px",
              }}
            >
              {thenDropdownSets.map((set, index) => (
                <Col span={24}>
                  <Row className="add-dropdown-row" gutter={12}>
                    <Col span={10}>
                      <Select
                        value={set.firstSelectedValue}
                        onChange={(value) =>
                          handleThenFirstSelectChange(value, index)
                        }
                        className="rules-dropdown"
                      >
                        {linuxOptions &&
                          linuxOptions.length > 0 &&
                          linuxOptions.map((option) => (
                            <Option value={option.value}>{option.value}</Option>
                          ))}
                      </Select>
                    </Col>
                    <Col
                      span={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      =
                    </Col>
                    <Col span={10}>
                      <Input></Input>
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                      {index > 0 && (
                        <DeleteOutlined
                          onClick={() => handleDeleteThenDropdown(index)}
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}
              <Col
                span={23}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  type="default"
                  onClick={createNewThenDropdown}
                  className="rules-dropdown"
                >
                  Add
                </Button>
              </Col>
            </Row>
          )}

          <Row>
            <Col
              offset={18}
              span={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              <Button
                type="primary"
                className="rules-dropdown"
                onClick={handleAddRule}
              >
                Add Rule
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddRulesV2;
