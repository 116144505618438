import { Avatar, Col, Drawer, Row, Select, Table, Tabs } from "antd";
import axios from "axios";
import React, { Children, useEffect, useState } from "react";
import {
  REACT_APP_API_PERMISSION,
  REACT_APP_API_RULES,
  REACT_APP_API_SEGMENTS,
  REACT_APP_API_URL,
} from "../../constants";
import moment from "moment-timezone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";

export const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const email = queryParams.get("email");

  const items = [
    {
      key: 1,
      label: "Google workspace",
      children: <UserTable email={email} />,
    },
    // {
    //   key: 2,
    //   label: "Workload Groups",
    //   // children: <ListworkloadGroups/>,
    // },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="title">
              {" "}
              <Link onClick={() => navigate(-1)}>
                <ArrowLeftOutlined />
              </Link>{" "}
              User Profile
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Avatar size={150} icon={<UserOutlined />} />
        </Col>
        <Col span={12} style={{ alignContent: "center" }}>
          <h2>{name}</h2>
          <h3>{email}</h3>
        </Col>
      </Row>

      <Row className="tab-container">
        <Col span={24}>
          <Tabs
            items={items}
            onChange={(key) => {
              // setCurrentTab(key);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export const UserTable = ({ email }) => {
  const [loading, setloading] = useState(false);
  const [isOpenPermissionDiff, setIsOpenPermissionDiff] = useState(false);
  const [isOpenPermission, setIsOpenPermission] = useState(false);
  const [originalPermission, setoriginalPermission] = useState(null);
  const [permission, setPermission] = useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    let payload = {
      username: email,
    };

    axios
      .post(`${REACT_APP_API_RULES}/getUsersandRules`, payload)

      .then(({ data }) => {
        let newData = data.map((user, i) => ({
          id: i + 1,
          ruleName: user.rules.rule_name,
          created_at: user.rules.created_at,
          metadata: user.rules.condition,
          originalPermission: user.data.metadata,
        }));
        setUserData(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPermission = () => {
    let payload = {
      username: email,
    };

    axios
      .post(`${REACT_APP_API_PERMISSION}/fetchPermissionHelper`, payload)

      .then(({ data }) => {
        setPermission(data.data.map((res) => res.metadata));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const headers = [
    {
      title: "Date_time",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <span>
          {moment(record?.created_at)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },

    {
      title: "Original permission",
      dataIndex: "originalPermission",
      key: "originalPermission",
      render: (_, val) => (
        <Link
          onClick={() => {
            setIsOpenPermissionDiff(true);
            setoriginalPermission(val);
          }}
        >
          View
        </Link>
      ),
    },

    {
      title: "Rule",
      dataIndex: "ruleName",
      key: "ruleName",
    },
    {
      title: "Rule Metadata",
      dataIndex: "metadata",
      key: "metadata",
      render: (_, rec) => (
        <span>
          {rec?.metadata.map((data, index) => (
            <span key={index}>
              {data.attribute} {data.operator} {data.value}
              {index !== rec.metadata.length - 1 && <br />}{" "}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Permission Changes",
      dataIndex: "permission",
      key: "permission",
      render: (_, rec) => (
        <Link
          onClick={() => {
            setIsOpenPermission(true);
            fetchPermission();
          }}
        >
          View
        </Link>
      ),
    },
    {
      title: "Action",
      dataIndex: "labels",
      key: "labels",
      render: (_, rec) => <span>Groups Added</span>,
    },
  ];
  const userDetail = [
    {
      Date_time: "03-18-2024 02:16 PST",
      originalPermission: `{
        "name": "Google Workspace",
        "app": "Google Workspace",
        "groups": {
          "gcp-billing-admins": "OWNER",
          "gcp-developers": "OWNER",
          "gcp-devops": "OWNER",
          "gcp-logging-admins": "OWNER",
          "gcp-logging-viewers": "OWNER",
          "gcp-monitoring-admins": "OWNER",
          "gcp-network-admins": "OWNER",
          "gcp-organization-admins": "OWNER",
          "gcp-security-admins": "OWNER"
        }
      }`,
      ruleName: "Rule_#Authnull_88",
      metadata: "app: Google Workspace",
      permission: `{
        "name": "Google Workspace",
        "app": "Google Workspace",
        "groups": {
          "gcp-billing-admins": "OWNER",
          "gcp-developers": "OWNER",
          "gcp-devops": "OWNER",
          "gcp-logging-admins": "OWNER",
          "gcp-logging-viewers": "OWNER",
          "gcp-monitoring-admins": "OWNER",
          "gcp-network-admins": "OWNER",
          "gcp-organization-admins": "OWNER",
          "gcp-security-admins": "OWNER"
        }
      }`,
      action: "Removed admin permissions",
    },
    {
      Date_time: "03-20-2024 02:16 PST",
      originalPermission: `{
        "name": "Google Workspace",
        "app": "Google Workspace",
        "Groups": {
          "authnull-test-env": "OWNER"
        }
      }`,
      ruleName: "Rule_#Authnull_88",
      metadata: "app: Google Workspace",
      permission: `{
        "name": "Google-Workspace",
        "app": "Google Workspace",
        "Groups": {
          "gcp-monitoring-admins": "_USER_MANAGEMENT_ADMIN_ROLE"
        }
      }`,
      action: "Logon denied",
    },
  ];
  return (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <Table
            className="userTable-container"
            columns={headers}
            dataSource={userData}
            pageSize={10}
            loading={loading}
          />
        </Col>
      </Row>
      {isOpenPermissionDiff && (
        <>
          <Drawer
            title={originalPermission ? "Original Permission" : "Permissions"}
            width={500}
            closable={() => setIsOpenPermissionDiff(false)}
            onClose={() => setIsOpenPermissionDiff(false)}
            open={isOpenPermissionDiff}
          >
            <>
              {originalPermission && (
                <Row className="commit-container">
                  <Col className="version-history" span={24}>
                    <>
                      <Col span={24}>
                        <div className="github-commit-card">
                          <div className="commit-header"></div>
                          <div className="commit-details">
                            <p className="commit-message">
                              {originalPermission.originalPermission.name}
                            </p>
                            <div className="commit-code">
                              <div className="code-line">
                                <pre className="code-snippet">
                                  <pre>
                                    {JSON.stringify(
                                      originalPermission.originalPermission,
                                      null,
                                      2
                                    )}
                                  </pre>
                                </pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  </Col>
                </Row>
              )}
            </>
          </Drawer>
        </>
      )}
      {isOpenPermission && (
        <>
          <Drawer
            title="Permissions"
            width={500}
            closable={() => setIsOpenPermission(false)}
            onClose={() => setIsOpenPermission(false)}
            open={isOpenPermission}
          >
            <>
              {permission && (
                <Row className="commit-container">
                  <Col className="version-history" span={24}>
                    <>
                      <Col span={24}>
                        {console.log(permission)}
                        <div className="github-commit-card">
                          <div className="commit-header"></div>
                          <div className="commit-details">
                            <p className="commit-message">
                              {permission[0].name}
                            </p>
                            <div className="commit-code">
                              <div className="code-line">
                                <pre className="code-snippet">
                                  <pre>
                                    {JSON.stringify(permission[0], null, 2)}
                                  </pre>
                                </pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  </Col>
                </Row>
              )}
            </>
          </Drawer>
        </>
      )}
    </>
  );
};
