import React, { useState } from "react";
import { Button, Drawer, Badge } from "antd";
import { BellFilled } from "@ant-design/icons";

const Notifications = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Badge status="processing" count={5} offset={[10, 10]} >
        <BellFilled size="large"  />
      </Badge>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </>
  );
};

export default Notifications;
