import React, { useState, useEffect } from "react";
import { Input, Button, Select, Row, Col, Typography, Collapse, Space } from "antd";
import axios from "axios";
import { REACT_APP_API_SEGMENTS } from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import { Logger } from "sass";

const { Title, Text } = Typography;
const { Option } = Select;

const GraphFilter = (props) => {
  const selectOptions = [`=`];
  const [selectAttributes, setSelectAttributes] = useState();
  const [selectOptionsMap, setSelectOptionsMap] = useState();
  const [ruleType, setRuleType] = useState("APP");

  useEffect(() => {
    fetchAttributes();
  }, []);

  useEffect(() => {
    fetchValues();
  }, [selectAttributes]);

  const fetchAttributes = async () => {
    let data = {
      pageNo: 1,
      pageSize: 100,
      filters: {
        attributeType: props.ruleType.toUpperCase(),
      },
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/masterattributeslist/getmasterattributeslist`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      setSelectAttributes(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchValues = async (val) => {
    const optionsMap = {};
    if (selectAttributes && selectAttributes.length > 0) {
      for (const item of selectAttributes) {
        const { attribute_label } = item;
        let data = {
          pageNo: 1,
          pageSize: 100,
          filters: {
            attributeName: attribute_label,
          },
        };
        try {
          const response = await axios.post(
            `${REACT_APP_API_SEGMENTS}/masterattributeslist/getmasterattributeslistvalues`,
            data,
            {
              headers: {
                Accept: "*/*",
                "User-Agent": "Thunder Client (https://www.thunderclient.com)",
                "Content-Type": "application/json",
              },
            }
          );
          let tempData = response.data.data.attribute_value;
          optionsMap[attribute_label] = tempData;
          setSelectOptionsMap(optionsMap);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const [dropdownSets, setDropdownSets] = useState([
    {
      id: 1,
      firstSelectedValue: selectAttributes && selectAttributes.length > 0 && selectAttributes[0],
      attributeLabel: "",
      option: "=",
      secondSelectedValue:
        selectOptionsMap &&
        selectOptionsMap.length > 0 &&
        selectOptionsMap[Object.keys(selectOptionsMap)[0]][0],
    },
  ]);

  const [formattedSelectedValues, setFormattedSelectedValues] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdownContainer = event.target.closest(".dropdowns-wrapper");
      if (!dropdownContainer) {
        setDropdownSets((prevDropdowns) => prevDropdowns.map((set) => ({ ...set, open: false })));
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const selectedPairs = dropdownSets.map(({ firstSelectedValue, secondSelectedValue }) => ({
      key: firstSelectedValue,
      value: secondSelectedValue,
    }));

    const formattedValues = selectedPairs.map(({ key, value }) => `${key}=${value}`).join(", ");
    setFormattedSelectedValues(formattedValues);
  }, [dropdownSets]);

  const handleSearch = () => {
    const selectedPairs = dropdownSets.map(({ firstSelectedValue, secondSelectedValue }) => ({
      key: firstSelectedValue,
      value: secondSelectedValue,
    }));
    props.setAttributes && props.setAttributes(selectedPairs);
    // console.log(selectedPairs);
  };
  const handleClear = async () => {
    console.log(dropdownSets);
    await setDropdownSets([
      {
        id: 1,
        firstSelectedValue: "",
        attributeLabel: "",
        option: "=",
        secondSelectedValue: "",
      },
    ]);
    console.log(dropdownSets, "aff");

    const selectedPairs = dropdownSets.map(({ firstSelectedValue, secondSelectedValue }) => ({
      key: null,
      value: null,
    }));
    props.setAttributes && props.setAttributes(selectedPairs);
  };

  const handleFirstSelectChange = (value, index, option) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].firstSelectedValue = value;
    updatedDropdownSets[index].attributeLabel = option ? option.children : null;
    updatedDropdownSets[index].secondSelectedValue = "";
    setDropdownSets(updatedDropdownSets);
  };

  const handleOptionChange = (value, index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].option = value;
    setDropdownSets(updatedDropdownSets);
  };

  const handleSecondSelectChange = (value, index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets[index].secondSelectedValue = value;
    setDropdownSets(updatedDropdownSets);
  };

  const handleDeleteDropdown = (index) => {
    const updatedDropdownSets = [...dropdownSets];
    updatedDropdownSets.splice(index, 1);
    setDropdownSets(updatedDropdownSets);
  };

  const createNewDropdown = () => {
    const newDropdownSet = {
      id: 1,
      firstSelectedValue: selectAttributes && selectAttributes.length > 0 && selectAttributes[0],
      attributeLabel: "",
      option: "=",
      secondSelectedValue:
        selectOptionsMap &&
        selectOptionsMap.length > 0 &&
        selectOptionsMap[Object.keys(selectOptionsMap)[0]][0],
    };
    setDropdownSets((prevDropdowns) => [...prevDropdowns, newDropdownSet]);
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <h5 className="search-label" style={{ marginBottom: "2rem" }}>
              Select Options To Filter
            </h5>
          </Col>
        </Row>
        <Row>
          {dropdownSets.map((set, index) => (
            <Col span={24}>
              <Row className="add-dropdown-row" gutter={12}>
                <Col span={9}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={set?.firstSelectedValue}
                    onChange={(value, option) => handleFirstSelectChange(value, index, option)}
                    className="rules-dropdown"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  >
                    {selectAttributes &&
                      selectAttributes.length > 0 &&
                      selectAttributes.map((attribute, index) => (
                        <Option key={attribute?.attribute_name} value={attribute?.attribute_name}>
                          {attribute?.attribute_label}
                        </Option>
                      ))}
                  </Select>
                </Col>
                <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                  <Select
                    value={set.option}
                    onChange={(value) => handleOptionChange(value, index)}
                    className="rules-dropdown"
                    filterOption={(input, option) => (option?.label ?? "").includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  >
                    {selectOptions.map((value) => (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={9}>
                  {set.firstSelectedValue !== null ? (
                    <Select
                      showSearch
                      value={set?.secondSelectedValue}
                      onChange={(value) => handleSecondSelectChange(value, index)}
                      className="rules-dropdown"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                    >
                      {selectOptionsMap &&
                        selectOptionsMap[set.attributeLabel] &&
                        selectOptionsMap[set.attributeLabel].map((value) => (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      value={set.secondSelectedValue}
                      onChange={(value) => handleSecondSelectChange(value, index)}
                      className="rules-dropdown"
                    >
                      {selectOptionsMap[Object.keys(selectOptionsMap)[0]][0].map((value) => (
                        <Option key={value} value={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  {index > 0 && (
                    <DeleteOutlined
                      onClick={() => handleDeleteDropdown(index)}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={7}></Col>
          <Col span={7}></Col>
          <Col
            span={7}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Button type="default" onClick={createNewDropdown} className="rules-dropdown">
              Add
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={7}></Col>
          <Col span={7}></Col>
          <Col
            span={7}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Space>
              {dropdownSets[0]?.firstSelectedValue?.length >= 1 && (
                <Button type="default" className="rules-dropdown" onClick={() => handleClear()}>
                  Clear
                </Button>
              )}

              <Button type="primary" className="rules-dropdown" onClick={() => handleSearch()}>
                Search
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GraphFilter;
