import { Col, Drawer, Row, Select, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { REACT_APP_API_SEGMENTS, OKTA_TOKEN } from "../../constants";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { logOut } from "../../common/logout";

const UserPermission = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [user, setUser] = useState();
  const [isOpenPermissionDiff, setIsOpenPermissionDiff] = useState(false);
  const [inheritedPermission, setInheritedPermission] = useState();
  const handleChangeUserName = (val, label) => {
    setUser(label?.label);
  };

  useEffect(() => {
    fetchUserName();
    fetchUserDetail();
  }, [user]);

  const fetchUserName = () => {
    axios
      .post(
        `${REACT_APP_API_SEGMENTS}/permissions/listUsers`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      )
      .then((res) => {
        console.log(res.data.data, "aa");
        setUserData(
          res.data.data.map((user, i) => ({
            value: i + 1,
            label: user,
          }))
        );
      })
      .catch((error) => {
        if (
          error.response.data === "Unauthorized" ||
          error.response?.status === 401
        ) {
          logOut();
        }
        console.error("Error fetching data:", error);
      });
  };

  const fetchUserDetail = () => {
    let payload = {
      filters: {
        username: user ? user : "",
      },
    };
    setLoading(true);
    axios
      .post(`${REACT_APP_API_SEGMENTS}/segments/showUserWithRule`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": OKTA_TOKEN(),
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setUserDetail(res.data.data);
      })
      .catch((err) => {
        if (
          err.response.data === "Unauthorized" ||
          err.response?.status === 401
        ) {
          logOut();
        }
        setLoading(false);
        console.error("Error fetching data:", err);
      });
  };

  const userHeader = [
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Rule",
      dataIndex: "ruleName",
      key: "ruleName",
      render: (_, record) => <span>{record.ruleName.ruleName}</span>,
    },
    {
      title: "Permissions",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => (
        <Link
          onClick={() => {
            console.log(record.inheritedPermission);
            setIsOpenPermissionDiff(true);
            setInheritedPermission(record);
          }}
        >
          View
        </Link>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      key: "approvedBy",
    },

    {
      title: "Created At",
      dataIndex: "createdat",
      key: "createdat",
      render: (_, record) => (
        <span>
          {moment(record.createdat)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedat",
      key: "updatedat",
      render: (_, record) => (
        <span>
          {moment(record.updatedat)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },
  ];

  const userSearchHeaders = [
    {
      title: "Rule",
      dataIndex: "ruleName",
      key: "ruleName",
      render: (_, record) => <span>{record.ruleName.ruleName}</span>,
    },

    {
      title: "Permissions",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => (
        <Link
          onClick={() => {
            console.log(record.inheritedPermission);
            setIsOpenPermissionDiff(true);
            setInheritedPermission(record);
          }}
        >
          View
        </Link>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      key: "approvedBy",
    },
    {
      title: "Created At",
      dataIndex: "createdat",
      key: "createdat",
      render: (_, record) => (
        <span>
          {moment(record.createdat)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedat",
      key: "updatedat",
      render: (_, record) => (
        <span>
          {moment(record.updatedat)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },
  ];

  const HighlightedJson = ({ data }) => {
    const highlightGroups = (jsonString) => {
      if (!jsonString) {
        return "";
      }
      return jsonString.replace(
        /("Groups": {[^}]+})/g,
        '<span style="color: yellow;">$1</span>'
      );
    };

    const highlightedData = highlightGroups(JSON.stringify(data, null, 2));

    return (
      <pre>
        <code dangerouslySetInnerHTML={{ __html: highlightedData }} />
      </pre>
    );
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="title">User Permission</h1>
          </div>

          <Row>
            <Col span={8}>
              <h5 className="search-label">Users</h5>
              <Select
                className="search-component"
                showSearch
                allowClear
                placeholder="Search user"
                onChange={handleChangeUserName}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={userData}
              />
            </Col>
          </Row>
          {user && (
            <Row style={{ marginTop: "2rem" }}>
              <Col>
                <h2>Search Result for {user}</h2>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Table
                className="userTable-container"
                columns={user ? userSearchHeaders : userHeader}
                dataSource={userDetail}
                pageSize={10}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenPermissionDiff && (
        <>
          <Drawer
            title="Permissions"
            width={800}
            closable={() => setIsOpenPermissionDiff(false)}
            onClose={() => setIsOpenPermissionDiff(false)}
            open={isOpenPermissionDiff}
          >
            <>
              <Row className="commit-container">
                <Col className="version-history" span={24}>
                  <>
                    <Col span={24}>
                      <div className="github-commit-card ">
                        <div className="commit-header"></div>
                        <div className="commit-details">
                          <p className="commit-message">
                            {console.log(
                              inheritedPermission?.ruleName?.ruleCondition?.map(
                                (val) => val?.attribute
                              )
                            )}
                            Rule
                          </p>
                          <div className="commit-code">
                            <div className="code-line">
                              <pre className="code-snippet">
                                {inheritedPermission?.ruleName?.ruleCondition?.map(
                                  (val) => (
                                    <Row>
                                      <Col>
                                        {val?.attribute} : {val.value}
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                </Col>
              </Row>

              <Row gutter={6}>
                <Col span={12}>
                  <Col span={24}>
                    <Col className="version-history" span={24}>
                      <h3 className="ant-drawer-title">Inherited Permission</h3>
                      <>
                        <Col span={24} style={{ marginTop: "1rem" }}>
                          <div className="github-commit-card ">
                            <div className="commit-header"></div>
                            <div className="commit-details">
                              <p className="commit-message">
                                {console.log(inheritedPermission)}
                                {
                                  inheritedPermission?.inheritedPermission
                                    ?.Metadata?.name
                                }
                              </p>
                              <div className="commit-code">
                                <div
                                  className="code-line"
                                  style={{ marginTop: "1rem" }}
                                >
                                  <pre className="code-snippet">
                                    <pre>
                                      {JSON.stringify(
                                        inheritedPermission?.inheritedPermission
                                          ?.Metadata,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </pre>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    </Col>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col className="version-history" span={24}>
                    <h3 className="ant-drawer-title">Permission Changes </h3>
                    <>
                      <Col span={24} style={{ marginTop: "1rem" }}>
                        <div className="github-commit-card ">
                          <div className="commit-header"></div>
                          <div className="commit-details">
                            <p className="commit-message">
                              {console.log(
                                inheritedPermission?.ruleName?.permissions
                                  ?.metadata
                              )}
                              {
                                inheritedPermission?.ruleName?.permissions
                                  ?.metadata?.name
                              }
                            </p>
                            <div className="commit-code">
                              <div
                                className="code-line"
                                style={{ marginTop: "1rem" }}
                              >
                                <pre className="code-snippet">
                                  <pre>
                                    <HighlightedJson
                                      data={
                                        inheritedPermission?.ruleName
                                          ?.permissions?.metadata
                                      }
                                    />
                                  </pre>
                                </pre>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  </Col>
                </Col>
              </Row>
            </>
          </Drawer>
        </>
      )}
    </>
  );
};

export default UserPermission;
