// import "./styles/index.scss";
import { Layout, Col, Row, Menu, Dropdown, Avatar } from "antd";
import Notifications from "./genericComponents/notifications";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Segement from "./segement/segement";
import QueryUsers from "./query/queryUsers";
import {
  RadarChartOutlined,
  UnorderedListOutlined,
  DotChartOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons/lib/icons";
import Rules from "./rules/rules";
import Dashboard from "./dashboard/dashboard";
// import AddRules from "./rules/addRules";
import ChatWidget from "./genericComponents/chatBot";
import LogList from "./auditLogs/log";
import AddSource from "./auditLogs/addSource";
import { logOut } from "../common/logout";
import UserPermission from "./userPermission/UserPermission";
import { UserProfile } from "./userProfile/UserProfile";
import Users from "./users/Users";
import AddRulesV2 from "./rules/addRulesV2";
import { useEffect, useState } from "react";
import ImportRules from "./rules/ImportRules";

const { Header, Sider, Content } = Layout;

function PrimaryLayout() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser(user);
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <span>{user}</span>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          onClick={() => {
            logOut();
          }}
          style={{ color: "blue" }}
        >
          <LogoutOutlined
            style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
          />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="app-container">
      <Header className="header-container">
        <Col>
          <h1 className="logo-container">
            <RadarChartOutlined />
            DIT
          </h1>
        </Col>
        <Col>
          <Row>
            <Col
              style={{
                marginTop: "0.2rem",
                marginRight: "2rem",
              }}
              className="notification-icon-container"
            >
              <Notifications />
            </Col>
            <Col className="notification-icon-container">
              <Dropdown overlay={menu} placement="bottomRight" arrow>
                <Avatar
                  size="medium"
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#efefef", color: "#000" }}
                />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Header>
      <Layout className="body-container" hasSider>
        <Sider className="left-menu-container">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            className="left-menu"
          >
            <Menu.Item
              icon={<DashboardOutlined />}
              key="dashboard"
              onClick={() => handleNavigation("/")}
            >
              Dashboard
            </Menu.Item>

            <Menu.Item
              key="segement1"
              onClick={() => handleNavigation("/segement")}
              icon={<UnorderedListOutlined />}
            >
              Segments
            </Menu.Item>

            <Menu.Item
              key="queryUsers"
              onClick={() => handleNavigation("/query/users")}
              icon={<DotChartOutlined />}
            >
              Query Users
            </Menu.Item>
            {/* <Menu.Item                               NOTE: NOT NEEDED , MAY BE WILL USER LATER
              key="permission"
              icon={<UserOutlined />}
              onClick={() => handleNavigation("/user/permission")}
            >
              User Permission
            </Menu.Item> */}
            <Menu.Item
              key="rulesList"
              onClick={() => handleNavigation("/rules")}
              icon={<RadarChartOutlined />}
            >
              Rules
            </Menu.Item>
            <Menu.Item
              key="profile"
              icon={<UserOutlined />}
              onClick={() => handleNavigation("/users")}
            >
              User
            </Menu.Item>
            <Menu.Item
              key="logList"
              icon={<DatabaseOutlined />}
              onClick={() => handleNavigation("/logs")}
            >
              Logs
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className="page-container">
          {/* <Router> */}
          {/* <Link to="/home">Home</Link>
            <Link to="/about">About</Link> */}

          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/segement" element={<Segement />} />
            <Route path="/query/users" element={<QueryUsers />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/rules/addRules" element={<AddRulesV2 />} />
            <Route path="/rules/importRules" element={<ImportRules />} />
            <Route path="/logs" element={<LogList />} />
            <Route path="/logs/addSource" element={<AddSource />} />

            <Route path="/" element={<Dashboard />} />

            <Route path="/user/permission" element={<UserPermission />} />
            <Route path="/user/profile" element={<UserProfile />} />
          </Routes>
          {/* </Router> */}

          <ChatWidget />
        </Content>
      </Layout>
    </Layout>
  );
}

export default PrimaryLayout;
