export const getDescription = (condition) => {
  if (condition === "browser") {
    return "The Browser that is used by an actor to perform an action.";
  } else if (condition === "risk_score") {
    return "Risk Score of the User trying to perform an action . it will be computed based on his authentication status";
  } else if (condition === "trusted_device") {
    return "Device which the user uses to perform actions all the time. If this Differs, we will get the value as TRUE/FALSE";
  } else if (condition === "trusted_network") {
    return "Network which the user uses to perform actions all the time. If this Differs, we will get the value as TRUE/FALSE";
  } else if (condition === "persona_value") {
    return "Persona value is derived from the user interaction with the system over the long term. ";
  } else if (condition === "confidence_score") {
    return "Whether the user is who he is claiming that he is. Per event or per session. ";
  } else if (condition === "trusted_location") {
    return "Identified as commonly logged in locations";
  } else if (condition === "trusted_source_ip") {
    return "IP of the machine which the user uses to perform actions all the time. If this Differs, we will get the value as TRUE/FALSE";
  } else if (condition === "login_success") {
    return "True if the last 3 logins were successfull";
  } else if (condition === "authn_fail_count") {
    return "Number of times authentication failed in the last 7 days Applies for linux and okta logs";
  } else if (condition === "nac") {
    return "When plugged in can it talk to broadcom network or not. Clearpass has the information necessary. ";
  } else if (condition === "authn_fail_count") {
    return "Number of times authentication failed in the last 7 days Applies for linux and okta logs";
  } else if (condition === "browser_change_count") {
    return "Number of days devices changed in the last 7 days";
  } else if (condition === "vpn_percent_count") {
    return "0-100% value of times user logged into vpn";
  } else if (condition === "device_change_count") {
    return "Number of times devices changed in the last 7 days";
  } else if (condition === "location_change_count") {
    return "Number of times location varied i the last 7 days";
  } else if (condition === "password_change_count") {
    return "Number of times passwords got changed in the last 7 day period";
  } else if (condition === "2fa_fail_count") {
    return "Number of times 2FA failed in the last 60 mins session";
  } else if (condition === "state") {
    return "The physical location where the client is making its request from";
  } else if (condition === "country") {
    return "The physical location where the client is making its request from";
  } else if (condition === "city") {
    return "The physical location where the client is making its request from";
  } else if (condition === "operating_system") {
    return "The OS that is used by an actor to perform an action";
  } else if (condition === "authentication_provider") {
    return "The authentication data of an action";
  } else if (condition === "credential_provider") {
    return "The authentication data of an action";
  }
};
