import React, { useState, useEffect } from "react";
import { Button, Col, Row, Select, Table, Tooltip, notification } from "antd";
import axios from "axios";

import {
  REACT_APP_API_SEGMENTS,
  OKTA_TOKEN,
  REACT_APP_API_GOOGLEWORKSPACE,
  REACT_APP_API_RULES,
} from "../../constants";
import { logOut } from "../../common/logout";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function ImportRules() {
  const [allGroups, setAllGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rulesData, setRulesData] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [groups, setGroups] = useState();
  const [isAddingRule, setIsAddingRule] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const initialGroups = rulesData.map((rule) => ({
    key: rule.key,
    groupsDropdown: [
      {
        permission: null,
        group: null,
        role: null,
      },
    ],
  }));

  const openNotification = (type, message) => {
    api[type]({
      message,
      placement: "top",
    });
  };

  useEffect(() => {
    setGroups(initialGroups);
  }, [rulesData]);

  const fetchListRules = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listImportedRules`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      const tempData = response.data.map((rule, index) => {
        const tempRule = {
          key: index + 1,
          ruleName: rule.ruleName,
          attribute: rule.attribute,
        };

        const rules_condition = rule.data.map((condition) => {
          const [attribute, value] = condition.split(":");
          return {
            attribute,
            operator: "=",
            value,
          };
        });

        tempRule.rules_condition = rules_condition;
        return tempRule;
      });

      setRulesData(tempData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_GOOGLEWORKSPACE}/getGroups`
      );
      let tempData = response.data.groups.map((data) => ({
        value: `${data.GroupName} (${data.Description})`,
        ...data,
      }));
      // console.log("Groups names", tempData);
      setAllGroups(tempData);
    } catch (error) {
      console.error("Error fetching rules data:", error);
    }
  };

  useEffect(() => {
    fetchListRules();
    fetchGroups();
  }, []);

  const handleAddDropdown = (record) => {
    const newGroup = {
      permission: null,
      group: null,
      role: null,
    };

    setGroups((prevGroups) => {
      const existingIndex = prevGroups.findIndex(
        (group) => group.key === record.key
      );

      if (existingIndex !== -1) {
        const updatedGroups = [...prevGroups];
        updatedGroups[existingIndex].groupsDropdown.push(newGroup);
        return updatedGroups;
      } else {
        return [...prevGroups, { key: record.key, groupsDropdown: [newGroup] }];
      }
    });
  };

  const handleDeleteDropdown = (index, dropdownIndex) => {
    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[index].groupsDropdown.splice(dropdownIndex, 1);
      return updatedGroups;
    });
  };

  const handleAddRule = async (
    rule_name,
    rules_condition,
    allowedGroupsData,
    deniedGroupsData
  ) => {
    setIsAddingRule(true);
    let payload = {
      rule_name,
      rules_condition,
      permission: {
        apiVersion: "v1",
        kind: "permission",
        metadata: {
          name: "Google Workspace",
          // app: appName && appName.value,
          allowed_groups: allowedGroupsData,
          denied_groups: deniedGroupsData,
        },
      },
      created_by: "sachin-sharma",
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/AddRule`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      setIsAddingRule(false);
      console.log(response);
    } catch (error) {
      setIsAddingRule(false);
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.log("Error adding rule:", error);
      openNotification("error", "Error adding rules");
    }
  };

  const handleAllAddRules = async () => {
    try {
      let allowedGroupsData = [];
      let deniedGroupsData = [];

      groups.forEach((group) => {
        if (selectedRowKeys.includes(group.key)) {
          let allowedRowData = [];
          let deniedRowData = [];
          group.groupsDropdown.forEach((val) => {
            if (val.permission === "allow") {
              allowedRowData.push({ [val.group]: val.role });
            } else {
              deniedRowData.push(val.group);
            }
          });
          if (allowedRowData.length === 0) {
            allowedGroupsData.push([]);
          } else {
            allowedGroupsData.push(allowedRowData);
          }
          if (deniedRowData.length === 0) {
            deniedGroupsData.push([]);
          } else {
            deniedGroupsData.push(deniedRowData);
          }
        }
      });

      for (const [index, rule] of selectedRules.entries()) {
        if (!isAddingRule) {
          await handleAddRule(
            rule.ruleName,
            rule.rules_condition,
            allowedGroupsData[index],
            deniedGroupsData[index]
          );
        }
      }

      setSelectedRules([]);
      setSelectedRowKeys([]);
      setGroups(initialGroups);

      openNotification("success", "Rules added successfully");
    } catch (error) {
      console.log("Error adding rules:", error);
      openNotification("error", "Error adding rules");
    }
  };

  const columns = [
    {
      title: "Rule Name",
      dataIndex: "ruleName",
      key: "ruleName",
      render: (_, record) => (
        <div style={{ width: "150px" }}>
          <span>{record.ruleName}</span>
        </div>
      ),
    },
    {
      title: "Rule",
      dataIndex: "rules_condition",
      key: "rules_condition",
      render: (_, record) => (
        <pre className="code-snippet" style={{ width: "100px" }}>
          <Tooltip title={record.attribute} placement="topLeft">
            {record.rules_condition &&
              record.rules_condition.map((condition, conditionIndex) => (
                <div key={conditionIndex} className="code-line">
                  <span className="code-keyword">{condition.attribute}</span>
                  <span className="code-operator"> {condition.operator} </span>
                  <span className="code-value">{condition.value}</span>
                </div>
              ))}
          </Tooltip>
        </pre>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Col>
          {groups.map((group, index) => {
            if (group.key === record.key) {
              return group.groupsDropdown.map((dropdown, dropdownIndex) => (
                <Row
                  gutter={16}
                  key={dropdownIndex}
                  style={{ marginBottom: "5px" }}
                >
                  <Col span={7}>
                    <p style={{ marginBottom: "5px", marginTop: "0" }}>
                      Select action
                    </p>
                    <Select
                      placeholder="Select action"
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        setGroups((prevGroups) => {
                          const groupIndex = prevGroups.findIndex(
                            (group) => group.key === record.key
                          );

                          if (groupIndex !== -1) {
                            const updatedGroups = [...prevGroups];
                            updatedGroups[groupIndex].groupsDropdown[
                              dropdownIndex
                            ].permission = val;
                            return updatedGroups;
                          } else {
                            return [
                              ...prevGroups,
                              {
                                key: record.key,
                                groupsDropdown: [
                                  { id: record.key, permission: val },
                                ],
                              },
                            ];
                          }
                        });
                      }}
                      value={dropdown.permission}
                    >
                      <Option value="allow">Allow</Option>
                      <Option value="deny">Deny</Option>
                    </Select>
                  </Col>
                  <Col span={7}>
                    <p style={{ marginBottom: "5px", marginTop: "0" }}>
                      Select group name
                    </p>
                    <Select
                      placeholder="Select group"
                      style={{ width: "100%" }}
                      value={dropdown.group}
                      onChange={(val) => {
                        setGroups((prevGroups) => {
                          const groupIndex = prevGroups.findIndex(
                            (group) => group.key === record.key
                          );

                          if (groupIndex !== -1) {
                            const updatedGroups = [...prevGroups];
                            updatedGroups[groupIndex].groupsDropdown[
                              dropdownIndex
                            ].group = val;
                            return updatedGroups;
                          } else {
                            return [
                              ...prevGroups,
                              {
                                key: record.key,
                                groupsDropdown: [
                                  { id: record.key, group: val },
                                ],
                              },
                            ];
                          }
                        });
                      }}
                    >
                      {allGroups.map((groupOption) => (
                        <Option
                          key={groupOption.GroupName}
                          value={groupOption.GroupName}
                        >
                          {groupOption.GroupName}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={7}>
                    {dropdown.permission === "allow" && (
                      <>
                        <p style={{ marginBottom: "5px", marginTop: "0" }}>
                          Select group name
                        </p>
                        <Select
                          placeholder="Select role"
                          style={{ width: "100%" }}
                          value={dropdown.role}
                          onChange={(val) => {
                            const updatedGroups = [...groups];
                            updatedGroups[index].groupsDropdown[
                              dropdownIndex
                            ].role = val;
                            setGroups(updatedGroups);
                          }}
                        >
                          <Option value="member">Member</Option>
                        </Select>
                      </>
                    )}
                  </Col>
                  <Col span={3}>
                    {group.groupsDropdown.length > 1 && (
                      <DeleteOutlined
                        onClick={() =>
                          handleDeleteDropdown(index, dropdownIndex)
                        }
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          marginTop: "30px",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ));
            }
          })}
          <Col span={3}>
            <Button
              onClick={() => handleAddDropdown(record)}
              style={{ marginTop: "10px" }}
            >
              Add
            </Button>
          </Col>
        </Col>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRules(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="rule-container">
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "22px",
        }}
      >
        <div>
          <h1 className="title">Import Rules</h1>
          <p>Select any rule to add.</p>
        </div>
      </div>

      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            type="primary"
            disabled={selectedRules.length === 0}
            onClick={() => handleAllAddRules()}
          >
            Add Rule
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={isLoading || isAddingRule}
            columns={columns}
            dataSource={rulesData}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
