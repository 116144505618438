import React, { useState, useEffect } from "react";
import { Button, Table, Pagination, Spin } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom"; // Replace with your routing library
// import '../../style/dynamicSegements.scss'; // Import your custom CSS for styling

const { Column } = Table;

const LogList = () => {
  const navigate = useNavigate();
  const [staticFilterData, setStaticFilterData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
let isLoading=true
  const logHeaders = [
    { title: "Log Import Source", dataIndex: "import", key: "import" },
    { title: "Configured By", dataIndex: "configured", key: "configured" },
    { title: "Last Run", dataIndex: "lastRun", key: "lastRun" },
    { title: "Logs Processed Today", dataIndex: "logsToday", key: "logsToday" },
    { title: "Total Logs Processed", dataIndex: "totalLogs", key: "totalLogs" },
  ];

  useEffect(() => {
    const generateRandomEntries = () => {
      const configured = ["Hussain", "Asif Ali", "Inam"];
      const lastRun = [
        "2-22-2023 22:00 PST",
        "3-22-2023 10:00 PST",
        "4-22-2023 09:00 PST",
        "5-22-2023 17:00 PST",
        "6-22-2023 20:00 PST",
        "7-22-2023 23:00 PST",
      ];
      const buList = ["32,232", "31,233", "30,235", "32,232", "32,232"];
      const entries = [];

      for (let i = 0; i < 10; i++) {
        const entry = {
          id: i + 1,
          import: i % 2 === 0 ? "OKTA" : "LINUX",
          configured: configured[Math.floor(Math.random() * configured.length)],
          lastRun: lastRun[Math.floor(Math.random() * lastRun.length)],
          logsToday: buList[Math.floor(Math.random() * buList.length)],
          totalLogs: buList[Math.floor(Math.random() * buList.length)],
        };
        entries.push(entry);
      }

      return entries;
    };

    const data = generateRandomEntries();
    setStaticFilterData(data);
  }, []);

  return (
    <div className="dynamic-segment-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 className="title">Logs List</h1>
        {/* <Button type="primary" onClick={() => navigate("./addSource")}>
          Add Source
        </Button> */}
      </div>
      {/* <div className="status-bar">
        {isLoading ? (<span style={{width:'100%', textAlign:'center', color:'#1677ff'}}>
          <Spin size="small" /> <span style={{color:'#1677ff', marginLeft:'1rem'}}>Imported 40 out of 2000 logs</span>
        </span>
          // Show a loading spinner if data is being fetched
        ) : (
          <span>Importing logs...</span>
        )}
      </div> */}
      <div className="query-result-container" style={{ marginTop: "2rem" }}>
        <Table dataSource={staticFilterData}>
          {logHeaders.map((header) => (
            <Column {...header} key={header.key || header.dataIndex} />
          ))}
        </Table>
      </div>
    </div>
  );
};

export default LogList;
