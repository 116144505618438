import React, { useEffect, useState, useRef } from "react";
import { Popover, Input, Button, List, Avatar } from "antd";
import { MessageFilled, SendOutlined, UserOutlined } from "@ant-design/icons";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const ChatWidget = () => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const chatContainerRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleVisible = async (val) => {
    if (val === true) {
      let tempMessages = [
        {
          type: "user",
          text: "Given the current list of segments, what other kinds of segments do you think we should define, and why?",
        },
        {
          type: "bot",
          text: "Give me a moment while I analyze your logs as well as your segments. Given your segments I believe that we should define the following 124 new segments. See full segment list With these segments, you should be able to create granular rules that will enable fine-grained control of permissions to the users.",
        },
        {
          type: "bot",
          text: "Can I go ahead and create the segments?",
        },
        {
          type: "user",
          text: "Yes",
        },
        {
          type: "bot",
          text: "Created the segments. Would you like me to create the rules?",
        },
        {
          type: "user",
          text: "Yes",
        },
      ];

      for (let i = 0; i < 6; i++) {
        await new Promise((resolve) => {
          tempMessages[i].id = i; // Add unique IDs to messages
          setMessages((prevMessages) => [...prevMessages, tempMessages[i]]);
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      }
    } else {
      setMessages([]);
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;
    let tempMessages = messages;
    const newMessage = { type: "user", text: inputValue, id: Date.now() }; // Add a unique ID to the new message
    tempMessages.push(newMessage);
    setMessages(tempMessages);
    setInputValue("");
    // Simulate bot response after a short delay
    setTimeout(() => {
      const botResponse = { type: "bot", text: "Created Rules", id: Date.now() };
      setMessages((messages) => [...messages, botResponse]);
    }, 1000);
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const content = (
    <div className="chat-widget-container">
      <div className="chat-messages" ref={chatContainerRef}>
        <TransitionGroup component={null}>
          {messages.slice().reverse().map((item, index) => (
            <CSSTransition
              key={item.id}
              classNames="message"
              timeout={300}
            >
              <List.Item className={`message ${item.type}`}>
                <List.Item.Meta
                  avatar={<UserOutlined />}
                  title={item.type === "user" ? "You" : "Bot"}
                  description={item.text}
                />
              </List.Item>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      <div className="chat-input">
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <Button type="primary" onClick={handleSendMessage}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      title="Chat"
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={(visible) => {
        setVisible(visible);
        handleVisible(visible);
      }}
    >
      <div className="chat-icon">
        <MessageFilled />
      </div>
    </Popover>
  );
};

export default ChatWidget;
