//DIRECTORY AND PRIVELAGED ACCESS MANAGEMENT
export const REACT_APP_API_SEGMENTS = process.env.REACT_APP_API_SEGMENTS;
export const REACT_APP_API_RULES = process.env.REACT_APP_API_RULES;
export const REACT_APP_API_PERMISSION = process.env.REACT_APP_API_PERMISSION;

//OKTA TOKEN
export const REACT_APP_OKTA_URL = process.env.REACT_APP_OKTA_URL;

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const REACT_APP_API_GOOGLEWORKSPACE =
  process.env.REACT_APP_API_GOOGLEWORKSPACE;

//TOKEN
export const OKTA_TOKEN = () => {
  let token = localStorage.getItem("token");
  return token;
};
