import "../../styles/dashboard.scss";
import ReactApexChart from "react-apexcharts";
import {
  Layout,
  Col,
  Row,
  Menu,
  Tabs,
  Select,
  Card,
  Table,
  Tag,
  Space,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { randomDateTimePST } from "../genericComponents/randomDateTimePST";
import kmeans from "kmeans";
const { TabPane } = Tabs;

const { Option } = Select;
function Dashboard() {
  const navigate = useNavigate();
  const [clusterData, setClusterData] = useState(generateCircularClusters());
  const [optionsHeatMap, setOptionsHeatMap] = useState({
    chart: {
      type: "heatmap",
    },
    xaxis: {
      type: "category",
      categories: [
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12am",
        "1pm",
        "2pm",
        "3am",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm",
        "12pm",
      ],
    },
    yaxis: {
      title: {
        text: "Hours",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB"],
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState("last7days");
  const [chartDataPermission, setChartDataPermission] = useState({
    series: [
      {
        name: "Permission Changes",
        data: [300, 400, 250, 500, 490, 600, 240],
      },
    ],
  });
  const [chartDataLogs, setChartDataLogs] = useState({
    series: [
      {
        name: "Log Changes",
        data: [30, 700, 450, 500, 490, 800, 240],
      },
    ],
  });
  const [chartDataRules, setChartDataRules] = useState({
    series: [
      {
        name: "Rule Changes",
        data: [100, 900, 550, 500, 390, 500, 640],
      },
    ],
  });
  const [chartDataSegments, setChartDataSegments] = useState({
    series: [
      {
        name: "Segments",
        data: [400, 500, 150, 300, 890, 800, 240],
      },
    ],
  });
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "last7days-chart",
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
  });

  const handleTimeRangeChange = (value) => {
    setSelectedTimeRange(value);
  };
  useEffect(() => {
    updateChartData();
  }, [selectedTimeRange]);

  const updateChartData = () => {
    if (selectedTimeRange === "last7days") {
      setChartDataPermission({
        series: [
          {
            name: "Permission Changes",
            data: [300, 400, 450, 500, 490, 600, 240],
          },
        ],
      });
      setChartDataLogs({
        series: [
          {
            name: "Log Changes",
            data: [300, 400, 450, 500, 490, 600, 240],
          },
        ],
      });
      setChartDataRules({
        series: [
          {
            name: "Rule Changes",
            data: [300, 400, 450, 500, 490, 600, 240],
          },
        ],
      });
      setChartDataSegments({
        series: [
          {
            name: "Segments",
            data: [300, 400, 450, 500, 490, 600, 240],
          },
        ],
      });

      setChartOptions({
        chart: {
          id: "last7days-chart",
        },
        xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      });
    } else if (selectedTimeRange === "lastMonth") {
      setChartDataPermission({
        series: [
          {
            name: "Permission Changes",
            data: [
              800, 750, 900, 950, 1000, 850, 800, 950, 1000, 950, 900, 850,
            ],
          },
        ],
      });
      setChartDataLogs({
        series: [
          {
            name: "Log Changes",
            data: [
              800, 750, 900, 950, 1000, 850, 800, 950, 1000, 950, 900, 850,
            ],
          },
        ],
      });
      setChartDataRules({
        series: [
          {
            name: "Rule Changes",
            data: [
              800, 750, 900, 950, 1000, 850, 800, 950, 1000, 950, 900, 850,
            ],
          },
        ],
      });
      setChartDataSegments({
        series: [
          {
            name: "Segments",
            data: [
              800, 750, 900, 950, 1000, 850, 800, 950, 1000, 950, 900, 850,
            ],
          },
        ],
      });
      setChartOptions({
        chart: {
          id: "lastMonth-chart",
        },
        xaxis: {
          categories: Array.from({ length: 12 }, (_, i) => `Week ${i + 1}`),
        },
      });
    } else if (selectedTimeRange === "today") {
      setChartDataPermission({
        series: [
          {
            name: "Permission Changes",
            data: [50, 60, 70, 80, 90, 100, 110, 120],
          },
        ],
      });
      setChartDataLogs({
        series: [
          {
            name: "Log Changes",
            data: [50, 60, 70, 80, 90, 100, 110, 120],
          },
        ],
      });

      setChartDataRules({
        series: [
          {
            name: "Rule Changes",
            data: [50, 60, 70, 80, 90, 100, 110, 120],
          },
        ],
      });

      setChartDataSegments({
        series: [
          {
            name: "Segments",
            data: [50, 60, 70, 80, 90, 100, 110, 120],
          },
        ],
      });

      setChartOptions({
        chart: {
          id: "today-chart",
        },
        xaxis: {
          categories: [
            "1 AM",
            "2 AM",
            "3 AM",
            "4 AM",
            "5 AM",
            "6 AM",
            "7 AM",
            "8 AM",
          ],
        },
      });
    }
  };

  const handlePointClick = () => {
    // Extract the data for the clicked segment
    navigate("/segement");

    // Redirect to the desired page with segment-specific data
    // You can use the clickedSegmentData to pass data to the redirected page
    // Replace "segment-details" with the actual route you want to navigate to
  };
  const heatmapData = [
    {
      name: "Sunday",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50, 60,
        70, 80, 90, 100,
      ],
    },
    {
      name: "Monday",
      data: [
        70, 23, 10, 0, 40, 0, 0, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50,
        60, 70, 80, 90, 100,
      ],
    },
    {
      name: "Tuesday",
      data: [
        20, 90, 10, 50, 0, 200, 0, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40,
        50, 60, 70, 80, 90, 100,
      ],
    },
    {
      name: "Wednesday",
      data: [
        0, 20, 0, 40, 10, 0, 10, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50,
        60, 70, 80, 90, 100,
      ],
    },
    {
      name: "Thursday",
      data: [
        29, 90, 90, 10, 0, 0, 90, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50,
        60, 70, 80, 90, 100,
      ],
    },
    {
      name: "Friday",
      data: [
        0, 50, 60, 80, 10, 0, 50, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50,
        60, 70, 80, 90, 100,
      ],
    },
    {
      name: "Saturday",
      data: [
        10, 20, 0, 50, 0, 0, 0, 0, 10, 20, 40, 30, 25, 20, 15, 20, 30, 40, 50,
        60, 70, 80, 90, 100,
      ],
    },
    // Add similar data for other days of the week
    // ...
  ];

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      tickAmount: 10,
      labels: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        show: false,
      },
    },
    markers: {
      size: 3,
    },
    legend: {
      onItemClick: {
        // Clicking on the series legend will call handleLegendClick
        fn: handlePointClick,
      },
    },
  };

  const handleClusterChange = () => {
    setClusterData(generateCircularClusters());
  };

  function generateRandomTime() {
    const randomDate = Math.floor(Math.random() * 30) + 1; // Random day between 1 and 30
    const randomMonth = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const randomYear = 2023; // You can adjust the year as per your requirement

    const randomHour = String(Math.floor(Math.random() * 24)).padStart(2, "0"); // Random hour between 0 and 23
    const randomMinute = String(Math.floor(Math.random() * 60)).padStart(
      2,
      "0"
    ); // Random minute between 0 and 59

    return `${String(randomMonth).padStart(2, "0")}-${String(
      randomDate
    ).padStart(2, "0")}-${randomYear} ${randomHour}:${randomMinute} PST`;
  }
  function generateCircularClusters() {
    const numClusters = 4;
    const pointsPerCluster = 100;
    const clusters = [];

    for (let i = 1; i <= numClusters; i++) {
      const clusterData = [];
      const centerX = Math.random() * 100 * i;
      const centerY = Math.random() * 100 * i;

      for (let j = 0; j < pointsPerCluster; j++) {
        const angle = (j / pointsPerCluster) * 2 * Math.PI;
        const radius = 5 + Math.random() * 10;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        clusterData.push({ x, y });
      }
      if (i === 1) {
        clusters.push({ name: `Segement 1`, data: clusterData });
      }
      if (i === 2) {
        clusters.push({ name: `Segement 2`, data: clusterData });
      }
      if (i === 3) {
        clusters.push({ name: `Segement 3`, data: clusterData });
      }
      if (i === 4) {
        clusters.push({ name: `Segement 4`, data: clusterData });
      }
    }

    return clusters;
  }

  const headers = [
    { key: "date", title: "Date Time", dataIndex: "date" },
    {
      key: "text",
      title: "Text",
      dataIndex: "text",
      render: (_, record) => (
        <pre
          style={{
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            textAlign: "left",
            padding: "0.5rem",
            justifyContent: "flex-start",
            display: "flex",
            fontSize: "0.9em",
          }}
        >
          {record.text}
        </pre>
      ),
    },
  ];

  let oktaData = Array.from({ length: 100 }).map((_, i) => ({
    id: i,
    date: generateRandomTime(),
    text: 'Oct 9 09:58:04 example.myaccessgateway.com WEB_CONSOLE SESSION LOCAL INFO USER_LOGOUT [SESSION_ID="xNQ45qBSM7iDSh3SJMYRIxud2NOEKKxCRE2xsHSH" SUBJECT="admin" REASON="USER_ACTION" REMOTE_IP="-" USER_AGENT="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"] User logout: admin',
  }));
  // Sample data for the bar chart

  // Sample data for log changes over time

  const cards = [
    { title: "Card 1", count: 10 },
    { title: "Card 2", count: 25 },
    { title: "Card 3", count: 15 },
    { title: "Card 4", count: 30 },
    { title: "Card 5", count: 5 },
    { title: "Card 6", count: 18 },
  ];
  const chartData3 = {
    options: {
      chart: {
        id: "heatmap-chart",
        type: "heatmap",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: "category",
        categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
      yaxis: {
        type: "category",
        categories: [
          "12am",
          "1am",
          "2am",
          "3am",
          "4am",
          "5am",
          "6am",
          "7am",
          "8am",
          "9am",
          "10am",
          "11am",
          "12pm",
          "1pm",
          "2pm",
          "3pm",
          "4pm",
          "5pm",
          "6pm",
          "7pm",
          "8pm",
          "9pm",
          "10pm",
          "11pm",
        ],
        reversed: true,
      },
      colors: ["#f8d347", "#b3e099", "#81d0e8", "#b3e099", "#f8d347"],
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          colorScale: {
            ranges: [
              {
                from: -30,
                to: 0,
                name: "low",
                color: "#00A100",
              },
              {
                from: 1,
                to: 20,
                name: "medium",
                color: "#128FD9",
              },
              {
                from: 21,
                to: 45,
                name: "high",
                color: "#FFB200",
              },
              {
                from: 46,
                to: 100,
                name: "extreme",
                color: "#FF0000",
              },
            ],
          },
        },
      },
    },
    series: [
      {
        name: "Sunday",
        data: [
          [48, 54],
          [22, 9],
          [2, 7],
          // ... add your data points here
        ],
      },
      {
        name: "Monday",
        data: [
          [48, 54],
          [22, 9],
          [2, 7],
          // ... add your data points here
        ],
      },
      {
        name: "Tuesday",
        data: [
          // ... add your data points here
        ],
      },
      // Add data for other days similarly
    ],
  };

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row>
          <Col span={21}>
            <h2 className="title">Dashboard</h2>
          </Col>
          <Col span={3}>
            <Select
              value={selectedTimeRange}
              style={{ width: 120, marginBottom: "1rem", marginLeft:'2rem' }}
              onChange={handleTimeRangeChange}
            >
              <Option value="last7days">Last 7 Days</Option>
              <Option value="lastMonth">Last Month</Option>
              <Option value="today">Today</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ marginBottom: "2rem" }}>
            <Row className="chart-container">
              <Col span={24}>
                <h3 className="search-label">Segments</h3>
                <ReactApexChart
                  options={chartOptions}
                  series={chartDataSegments.series}
                  type="bar"
                  height={350}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row gutter={32} className="chart-first-row">
                  <Col span={12}>
                    <Row className="chart-container">
                      <Col span={24}>
                        <h3 className="search-label">Permission Changes</h3>
                        <ReactApexChart
                          options={chartOptions}
                          series={chartDataPermission.series}
                          type="line"
                          height={350}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row className="chart-container">
                      <Col span={24}>
                        <h3 className="search-label">Log Changes</h3>
                        <ReactApexChart
                          options={chartOptions}
                          series={chartDataLogs.series}
                          type="bar"
                          height={350}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={12}>
                    <Row className="chart-container">
                      <Col span={24}>
                        <h3 className="search-label">Rule Changes</h3>
                        <ReactApexChart
                          options={chartOptions}
                          series={chartDataRules.series}
                          type="bar"
                          height={350}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row className="chart-container">
                      <Col span={24}>
                        <h3 className="search-label">Users By Time Of Day</h3>
                        <ReactApexChart
                          options={optionsHeatMap}
                          series={heatmapData}
                          type="heatmap"
                          height={350}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <h2 className="dashboard-table-title">Data Toolbar</h2>
                <Row>
                  <Col span={24} style={{ marginTop: "1rem" }}>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span onClick={()=>navigate('/segement')}>67645</span>
                          </div>
                          <div className="static-text">Segements</div>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span onClick={()=>navigate('/query/users')}>50432</span>
                          </div>
                          <div className="static-text">Dynamic Queries</div>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span onClick={()=>navigate('/logs')}>48902</span>
                          </div>
                          <div className="static-text">Audit Events</div>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span>43092</span>
                          </div>
                          <div className="static-text">Static Queries</div>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span>78092</span>
                          </div>
                          <div className="static-text">Permission Changes</div>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card title={false} className="card">
                          <div className="counter">
                            <span>23098</span>
                          </div>
                          <div className="static-text">Assets Identified</div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <h2 className="dashboard-table-title">Audit Logs</h2>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Okta Session Log" key="1">
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Table columns={headers} dataSource={oktaData} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="DID Authentication Log" key="2">
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Table columns={headers} dataSource={oktaData} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Linux Authentication Log" key="3">
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Table columns={headers} dataSource={oktaData} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </Tabs> */}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Dashboard;
