import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Table,
  Drawer,
  Spin,
  Dropdown,
  Menu,
  Tooltip
} from "antd";
import "../../styles/gitHub.scss";
import axios from "axios";
import "../../styles/rules.scss";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import {
  REACT_APP_API_RULES,
  REACT_APP_API_PERMISSION,
  OKTA_TOKEN,
} from "../../constants";
import { logOut } from "../../common/logout";
import { getDescription } from "../../common/getDescription";

const { Option } = Select;

const Rules = () => {
  const navigate = useNavigate();
  const [appName, setAppName] = useState("Google Workspace");
  const [permissionVisible, setPermissionVisible] = useState();
  const [rules, setRules] = useState();
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState();
  const [commits, setCommits] = useState();
  const [ruleType, setRuleType] = useState("APP");
  const [commitVisible, setCommitVisible] = useState(false);
  const [endpoints, setEndpoints] = useState();
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const onChange = (value) => {
    console.log(`selected ${value}`);
    setRuleType(value);
  };

  const fetchEndpoints = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/listofendpoints`,
        {},
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setEndpoints(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/getUserCretedRules`,
        {},
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      setUsers(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCommit = async (val) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_API_PERMISSION}/getFileChanges`,
        { foldername: val.rule_name },
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      setCommits(tempData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchEndpoints();
    fetchUsers();
  }, []);
  useEffect(() => {
    fetchData();
  }, [userName, appName]);
  const columns = [
    {
      title: "Rule Name",
      dataIndex: "rule_name",
      key: "rule_name",
    },
    {
      title: "Rule",
      dataIndex: "rules_condition",
      key: "rules_condition",
      render: (_, record) => (
        <pre className="code-snippet">
          {record.rules_condition &&
            record.rules_condition.map(
              (condition, conditionIndex) =>
                condition.attribute !== "permission" && (
                  <div key={conditionIndex} className="code-line">
                    <Tooltip
                      title={getDescription(condition.attribute)}
                      placement="topLeft"
                    >
                      <span className="code-keyword">
                        {condition.attribute}
                      </span>
                      <span className="code-operator">
                        {condition.operator}
                      </span>
                      <span className="code-value">{condition.value}</span>
                    </Tooltip>
                  </div>
                )
            )}
        </pre>
      ),
    },

    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <span>
          {moment(record.created_at)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (_, record) => (
        <span>
          {moment(record.updated_at)
            .tz("America/Los_Angeles")
            .format("MM-DD-YYYY  HH:mm z")}
        </span>
      ),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Approval Status",
      key: "approveStatus",
      dataIndex: "approveStatus",
      render: (_, record) => <span>Approved</span>,
    },
    {
      title: "Approved By",
      key: "approvedBy",
      dataIndex: "approvedBy",
      render: (_, record) => <span>asif@broadcom.com</span>,
    },

    {
      title: "Rule History",
      key: "view",
      dataIndex: "view",
      fixed: "right",
      render: (_, record) => (
        <a
          onClick={() => {
            setRule(record);
            fetchCommit(record);
            setCommitVisible(true);
          }}
        >
          View
        </a>
      ),
    },
  ];
  const appList = [
    "Google Workspace",
    "Incorta",
    "JIRA",
    "Box",
    "Workato",
    "Wolken",
  ];
  const fetchData = async () => {
    let data = {
      pageNo: 1,
      pageSize: 1000,
      filter: {
        userName: userName,
        status: "",
        appName: appName,
      },
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/ListRules`,
        data,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      setRules(response.data.data);
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error fetching data:", error);
    }
  };

  const menuItem = () => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => navigate("./addRules")}>
          Add Rule
        </Menu.Item>
        <Menu.Item key="2" onClick={() => navigate("./importRules")}>
          Import Rule
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="rule-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 className="title">Rules</h1>
        <Dropdown overlay={menuItem} trigger={["click"]}>
          <Button type="primary">Add Rule</Button>
        </Dropdown>
      </div>
      <Row gutter={16}>
        <Col span={8}>
          <h5 className="search-label">Select App or endpoint</h5>
          <Select
            className="search-component"
            showSearch
            defaultValue={ruleType}
            onChange={onChange}
          >
            <Option value="APP">App</Option>
            <Option value="ENDPOINT">Endpoint</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row className="search-box-container" gutter={16}>
            {ruleType === "APP" ? (
              <Col span={8}>
                <h5 className="search-label">Filter By App</h5>
                <Select
                  value={appName}
                  onChange={(value) => setAppName(value)}
                  placeholder="Search App Name"
                  className="search-component"
                >
                  {appList.map((app) => (
                    <Option key={app} value={app}>
                      {app}
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : (
              <Col span={8}>
                <h5 className="search-label">Filter By Endpoint</h5>
                <Select
                  className="search-component"
                  showSearch
                  placeholder="Search endpoint name"
                  labelInValue={true}
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={endpoints}
                  style={{ width: "19rem" }}
                />
              </Col>
            )}
            <Col span={8}>
              <h5 className="search-label">Username</h5>
              <Select
                onChange={(value) => setUserName(value)}
                placeholder="Search User Name"
                className="search-component"
              >
                {users &&
                  users.length > 0 &&
                  users.map((user) => (
                    <Option key={user} value={user}>
                      {user}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={8}>
              <h5 className="search-label">Status</h5>
              <Select placeholder="Select Status" className="search-component">
                <Option value="Active">Created</Option>
                <Option value="Active">Active</Option>
                <Option value="Active">Inactive</Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="commit-container">
        <Col span={24} className="version-history">
          {/* Replace this part with your dynamic version history data */}

          <Table
            columns={columns}
            dataSource={rules}
            scroll={{
              x: 2000,
            }}
          />

          {/* Repeat the version history and code snippet sections for each entry */}
          {/* ... */}
        </Col>
        <Drawer
          title="Permission Changes"
          placement="right"
          open={permissionVisible}
          onClose={() => setPermissionVisible(false)}
          width={500}
        >
          <Row className="commit-container">
            <Col className="version-history" span={24}>
              {/* Replace this part with your dynamic version history data */}
              {rule ? (
                <>
                  <Col span={24}>
                    <div className="github-commit-card">
                      <div className="commit-header">
                        <p className="commit-info">
                          Last updated by {rule.created_by} on{" "}
                          {moment(rule.updated_at)
                            .tz("America/Los_Angeles")
                            .format("MMM DD, YYYY")}
                        </p>
                      </div>
                      <div className="commit-details">
                        <p className="commit-message">{rule.rule_name}</p>
                        <div className="commit-code">
                          {rule.rules_condition &&
                            rule.rules_condition.map(
                              (condition, conditionIndex) =>
                                condition.attribute !== "permission" && (
                                  <pre className="code-snippet">
                                    <div
                                      key={conditionIndex}
                                      className="code-line"
                                    >
                                      If{" "}
                                      <span className="code-keyword">
                                        {condition.attribute}
                                      </span>
                                      <span className="code-operator">
                                        {condition.operator}
                                      </span>
                                      <span className="code-value">
                                        {condition.value}
                                      </span>
                                    </div>
                                  </pre>
                                )
                            )}
                          <div
                            className="code-line"
                            style={{ marginTop: "1rem" }}
                          >
                            Then permission{" "}
                            <pre className="code-snippet">
                              <pre>
                                {JSON.stringify(
                                  rule.permission.metadata,
                                  null,
                                  2
                                )}
                              </pre>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : null}
            </Col>
          </Row>
        </Drawer>
        <Drawer
          title="Rule Changes"
          placement="right"
          open={commitVisible}
          onClose={() => setCommitVisible(false)}
          width={500}
        >
          <Row className="commit-container">
            {loading ? (
              <span style={{ marginTop: "45%", marginLeft: "45%" }}>
                <Spin size="large" tip="Loading..." />
              </span>
            ) : (
              <Col className="version-history" span={24}>
                {/* Replace this part with your dynamic version history data */}
                {commits && commits.length > 0 ? (
                  <>
                    {commits.map((commit, index) => (
                      <Col span={24}>
                        <div className="github-commit-card">
                          <div className="commit-header">
                            <p className="commit-info">
                              Committed by {commit.AuthorEmail}
                            </p>
                          </div>
                          <div className="commit-details">
                            <p className="commit-message">
                              {commit.CommitMessage}
                            </p>
                            <div className="commit-code">
                              <>
                                {commit.FilesChanged &&
                                  commit.FilesChanged.length > 0 &&
                                  commit.FilesChanged.map((rule) => (
                                    <>
                                      {rule.Rules && (
                                        <div
                                          className="commit-code"
                                          style={{ border: "none" }}
                                        >
                                          <h5 style={{ marginTop: "0" }}>
                                            {rule.Filename}
                                          </h5>
                                          {rule.Rules &&
                                            rule.Rules.map(
                                              (condition, conditionIndex) =>
                                                condition.attribute !==
                                                  "permission" && (
                                                  <>
                                                    <pre className="code-snippet">
                                                      <div
                                                        key={conditionIndex}
                                                        className="code-line"
                                                      >
                                                        If{" "}
                                                        <span className="code-keyword">
                                                          {condition.attribute}
                                                        </span>
                                                        <span className="code-operator">
                                                          {condition.operator}
                                                        </span>
                                                        <span className="code-value">
                                                          {condition.value}
                                                        </span>
                                                      </div>
                                                    </pre>
                                                  </>
                                                )
                                            )}
                                        </div>
                                      )}
                                      {rule.Permissions && (
                                        <div
                                          className="commit-code"
                                          style={{ border: "none" }}
                                        >
                                          <h5 style={{ marginTop: "0" }}>
                                            {rule.Filename}
                                          </h5>
                                          <div
                                            className="commit-code"
                                            style={{ border: "none" }}
                                          >
                                            <div
                                              className="code-line"
                                              style={{ marginTop: "1rem" }}
                                            >
                                              <pre className="code-snippet">
                                                <pre>
                                                  {JSON.stringify(
                                                    rule.Permissions.metadata,
                                                    null,
                                                    2
                                                  )}
                                                </pre>
                                              </pre>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : null}
              </Col>
            )}
          </Row>
        </Drawer>
      </Row>
    </div>
  );
};

export default Rules;
