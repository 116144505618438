function getRandomDateTimeInPST() {
    const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  
    // Get the current date in UTC
    const currentDate = new Date();
  
    // Get the offset in minutes for PST (Pacific Standard Time)
    const pstOffsetMinutes = -480; // UTC offset for PST is -8 hours (in minutes)
  
    // Calculate the offset in milliseconds
    const pstOffsetMilliseconds = pstOffsetMinutes * 60 * 1000;
  
    // Adjust the current date to PST
    const pstDate = new Date(currentDate.getTime() + pstOffsetMilliseconds);
  
    // Generate random day, month, and year within a range
    const randomDay = getRandomInt(1, 31); // Assuming 31 days in a month
    const randomMonth = getRandomInt(1, 12);
    const randomYear = getRandomInt(2000, 2022); // Assuming a range of years from 2000 to 2021
  
    // Set the generated day, month, and year to the PST date
    pstDate.setDate(randomDay);
    pstDate.setMonth(randomMonth - 1); // Months are zero-indexed, so subtract 1 from randomMonth
    pstDate.setFullYear(randomYear);
  
    // Generate random hours, minutes, and seconds within a range
    const randomHours = getRandomInt(0, 23);
    const randomMinutes = getRandomInt(0, 59);
    const randomSeconds = getRandomInt(0, 59);
  
    // Set the generated hours, minutes, and seconds to the PST date
    pstDate.setHours(randomHours);
    pstDate.setMinutes(randomMinutes);
    pstDate.setSeconds(randomSeconds);
  
    // Format the date and time as "mm-dd-yyyy HH:mm:ss PST"
    const month = String(pstDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month to display it correctly
    const day = String(pstDate.getDate()).padStart(2, '0');
    const year = pstDate.getFullYear();
    const hours = String(pstDate.getHours()).padStart(2, '0');
    const minutes = String(pstDate.getMinutes()).padStart(2, '0');
    const seconds = String(pstDate.getSeconds()).padStart(2, '0');
  
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} PST`;
  }
  
  // Example usage:
  export const randomDateTimePST = getRandomDateTimeInPST();