import React from "react";
import { Button, Input, Form, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom"; // Replace with your routing library
// import '../../style/query.scss'; // Import your custom CSS for styling

const AddSource = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    // Handle form submission
    console.log("Form values:", values);
  };

  return (
    <div className="query-container">
      <h1 className="title">Add Source</h1>

      <div >
        <Row>
          <Col span={12} style={{marginTop:'2rem'}}>
            <Form onFinish={onFinish}>
              <div className="bottom-section">
                <Form.Item label="Name" name="name">
                  <Input placeholder="Enter name..." />
                </Form.Item>
              </div>
              <div className="bottom-section">
                <Form.Item label="URL" name="url">
                  <Input placeholder="Enter Url" />
                </Form.Item>
              </div>
              <div className="bottom-section">
                <Form.Item label="Token" name="token">
                  <Input placeholder="Enter token" />
                </Form.Item>
              </div>
              <Row justify="end" style={{ marginTop: "3rem" }}>
                <Col>
                  <Button
                     onClick={() => navigate(-1)}
                    icon={<ArrowLeftOutlined />}
                    style={{marginRight:'1rem'}}
                  >
                    Back
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddSource;
