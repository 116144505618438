import "../../styles/queryusers.scss";
import axios from "axios";
import moment from "moment-timezone";
import {
  Layout,
  Col,
  Row,
  Menu,
  Tabs,
  Select,
  Table,
  Tag,
  Space,
  Input,
  Drawer,
  Button,
  Modal,
  Collapse,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  REACT_APP_API_SEGMENTS,
  REACT_APP_API_RULES,
  REACT_APP_API_PERMISSION,
  OKTA_TOKEN,
} from "../../constants";
import { useEffect, useState } from "react";
import RuleContainer from "../rules/modalRules";
import GraphFilter from "./graphFilter";
import { logOut } from "../../common/logout";

const { Option } = Select;
const { Panel } = Collapse;
function QueryUsers() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [graphFilterValue, setGraphFilterValue] = useState(
    "app=confluence, location=united states, trusted_network=home wi-fi network, okta_session=active"
  );
  const [endpoints, setEndpoints] = useState();
  const [loading, setLoading] = useState();
  const [rule, setRule] = useState();
  const [selectedApp, setSelectedApp] = useState("");
  const [userHeaders, setUserHeaders] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [permissionVisible, setPermissionVisible] = useState(false);
  const [inheritedPermissionVisible, setInheritedPermissionVisible] =
    useState(false);
  const [graphFilter, setGraphFilter] = useState(false);
  const [ruleType, setRuleType] = useState("App");
  const [appList, setApplist] = useState();
  const [buList, setBuList] = useState();
  const [selectedBu, setSelectedBu] = useState();
  const [queryUsersData, setQueryUsersData] = useState();
  const [permission, setPermission] = useState();
  const [inheritedPermission, setInheritedPermission] = useState();
  const [queryPermissionData, setQueryPermissionData] = useState();
  const [attributes, setAttributes] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [inheritedVal, setInheritedVal] = useState(1);

  const fetchEndpoints = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/listofendpoints`,
        {},
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setEndpoints(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_PERMISSION}/fetchBranch`,
        { foldername: [] },
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      // tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setRule(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchQueryUsers = async (page, val) => {
    setLoading(true);
    let keyDataParis = val?.map((data) => data?.key);
    let keysValue = keyDataParis?.join("-");
    let data = {
      pageNo: page ? page : currentPage,
      pageSize: 10,
      keys: val && val[0].key !== null ? `app-bu-${keysValue}` : "",
      filters: {
        // segmentType: ruleType,
        // bu: selectedBu,
        // appname: selectedApp,
      },
    };
    if (ruleType) {
      data.filters["segmentType"] = ruleType;
    }
    if (selectedBu) {
      data.filters["bu"] = selectedBu;
    }
    if (selectedApp) {
      data.filters["appname"] = selectedApp;
    }
    if (val) {
      val.forEach((item) => {
        data.filters[item.key] = item.value; // Add each key-value pair to attributes
      });
    } else if (attributes) {
      attributes.forEach((item) => {
        data.filters[item.key] = item.value; // Add each key-value pair to attributes
      });
    }
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/getsegmentsfromgraph`,
        data,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      const { rule, email } = response?.data;

      const mergedData = rule?.map((item, index) => ({
        ...item,
        email: email[index].email,
      }));

      if (response?.data?.email === null) {
        setQueryUsersData();
        setLoading(false);
      } else {
        setQueryUsersData(mergedData);

        // setPermission(response.data.permission);
        // setInheritedPermission(response.data.inheritedPermissions);
        let tempPermissionData = Array.from({ length: 10 }, (_, i) => ({
          id: i + 1,
          user:
            response.data.data &&
            response.data.data.length > 0 &&
            response.data.data[i].user,
          time: generateRandomTime(),
          permission: "Yes",
          rules: "Show",
          City:
            response.data.data &&
            response.data.data.length > 0 &&
            response.data.data[i].city,
        }));
        setTotalCount(response?.data?.count);
        setQueryPermissionData(tempPermissionData);
        setLoading(false);
      }
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchBasePermission = (record) => {
    let data = {
      username: record.email,
      rule: [`bu=${record.bu}`, `appname=${record.appname}`],
    };
    axios
      .post(`${REACT_APP_API_PERMISSION}/fetchBasePermissions`, data)
      .then((res) => {
        setInheritedPermission(res.data.data);
        setPermission(res.data.enforced_permissions);
      });
  };

  useEffect(() => {
    // fetchPermissions();
    fetchEndpoints();
    fetchApps();
    fetchBu();
    let userData = generateRandomEntries();

    setUserData(userData);
    setUserHeaders([
      {
        title: "User",
        dataIndex: "email",
        key: "email",
      },
      // {
      //   title: "BU",
      //   dataIndex: "buname",
      //   key: "buname",
      // },
      // {
      //   title: "Location",
      //   dataIndex: "country",
      //   key: "country",
      // },
      // {
      //   title: "City",
      //   dataIndex: "city",
      //   key: "City",
      // },
      {
        title: "Rule",
        dataIndex: "rule",
        key: "rule",
        render: (_, record) => (
          <pre className="code-snippet">
            {record &&
              Object.entries(record)
                .filter(([key]) => key !== "email")
                .slice(0, 10)
                .map(([key, value]) => (
                  <div key={key} className="code-line">
                    <span className="code-keyword">{key}</span>
                    <span className="code-operator">=</span>
                    <span className="code-value">{value}</span>
                  </div>
                ))}
          </pre>
        ),
      },
      {
        title: "Inherited Permissions",
        dataIndex: "permission",
        key: "permission",
        render: (_, record) => (
          <span>
            <a
              onClick={() => {
                fetchBasePermission(record);

                setInheritedVal(Math.floor(Math.random() * 5));
                setInheritedPermissionVisible(true);
              }}
            >
              View
            </a>
          </span>
        ),
      },
      {
        title: "Permissions",
        dataIndex: "permission",
        key: "permission",
        render: (_, record) => (
          <span>
            {/* {record.permission}{" "} */}
            <a
              onClick={() => {
                fetchBasePermission(record);
                setPermissionVisible(true);
              }}
            >
              View
            </a>
          </span>
        ),
      },
    ]);
  }, []);
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onAppChange = (e) => {
    setSelectedApp(e.value);
  };
  const onBuChange = (e) => {
    setSelectedBu(e.value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const fetchBu = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listbu`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      let tempData = response.data.data;

      tempData = tempData.map((app) => ({
        ["key"]: app.bu,
        value: app.bu,
      }));
      // tempData.push({key:"all", value:"All"})

      setBuList(tempData);
      setSelectedBu(tempData[0].value);
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error fetching data:", error);
    }
  };
  const fetchApps = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listappnames`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      let tempData = response.data.data;

      tempData = tempData.map((app) => ({
        ["key"]: app.appName,
        value: app.appName,
      }));
      // tempData.push({key:"all", value:"All"})

      setApplist(tempData);
      setSelectedApp(tempData[0].value);
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error fetching data:", error);
    }
  };

  function generateRandomSegmentId() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomInteger = Math.floor(Math.random() * 1000); // Random integer between 0 and 999
    const randomAlphabet =
      alphabet[Math.floor(Math.random() * alphabet.length)];
    return `${randomAlphabet}${randomInteger}`;
  }
  const parseTags = (val) => {
    const tagRegex = /\[([^=\]]+)=([^=\]]+)]/g;
    const tags = [];
    let match;

    while ((match = tagRegex.exec(val))) {
      const key = match[1];
      const value = match[2];
      tags.push({ key, value });
    }

    return tags;
  };

  function generateRandomEntries() {
    const domains = ["@kloudone.com"];
    const buOptions = ["EES", "ABD", "Project Management", "Sales", "IT"];
    const peopleNames = [
      "John Smith",
      "Mary Johnson",
      "Robert Lee",
      "Emily Williams",
      "Michael Brown",
    ];
    const entries = [];
    for (let i = 0; i < 10; i++) {
      const randomName =
        peopleNames[Math.floor(Math.random() * peopleNames.length)];
      const entry = {
        // id: i + 1,
        oktaId: `${randomName.replace(/\s+/g, "").toLowerCase()}${
          domains[Math.floor(Math.random() * domains.length)]
        }`,
        appsProvisioned: String(Math.floor(Math.random() * 200) + 1),
        bu: buOptions[Math.floor(Math.random() * buOptions.length)],
        location: "USA",
        city: "Cape Town, SA",
      };

      entries.push(entry);
    }

    return entries;
  }

  const permissionHeaders = [
    { key: "user", title: "User", dataIndex: "user" },
    { key: "time", title: "Last Event Time", dataIndex: "time" },
    {
      title: "Permission Changes",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => (
        <span>
          {record.permission}{" "}
          <a onClick={() => setPermissionVisible(true)}>[View History]</a>
        </span>
      ),
    },
    {
      title: "Rules",
      key: "rules",
      dataIndex: "rules",
      render: (_, record) => (
        <>
          <a
            onClick={() => {
              setDrawerVisible(true);
            }}
          >
            View
          </a>
        </>
      ),
    },
  ];
  let samplePermissionData = {
    oktaId: "@kloudone.com ",
    time: "02-23-2023 13:00 PST",
    permission: "Yes",
    City: "USA, USA",
  };
  function generateRandomTime() {
    const randomDate = Math.floor(Math.random() * 30) + 1; // Random day between 1 and 30
    const randomMonth = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const randomYear = 2023; // You can adjust the year as per your requirement

    const randomHour = String(Math.floor(Math.random() * 24)).padStart(2, "0"); // Random hour between 0 and 23
    const randomMinute = String(Math.floor(Math.random() * 60)).padStart(
      2,
      "0"
    ); // Random minute between 0 and 59

    return `${String(randomMonth).padStart(2, "0")}-${String(
      randomDate
    ).padStart(2, "0")}-${randomYear} ${randomHour}:${randomMinute} PST`;
  }

  const auditHeaders = [
    { key: "date", title: "Date Time", dataIndex: "date" },
    {
      key: "text",
      title: "Text",
      dataIndex: "text",
      render: (_, record) => (
        <pre
          style={{
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            textAlign: "left",
            padding: "0.5rem",
            justifyContent: "flex-start",
            display: "flex",
            fontSize: "0.9em",
          }}
        >
          {record.text}
        </pre>
      ),
    },
  ];

  let auditData = Array.from({ length: 100 }).map((_, i) => ({
    id: i,
    date: generateRandomTime(),
    text: 'Oct 9 09:58:04 example.myaccessgateway.com WEB_CONSOLE SESSION LOCAL INFO USER_LOGOUT [SESSION_ID="xNQ45qBSM7iDSh3SJMYRIxud2NOEKKxCRE2xsHSH" SUBJECT="admin" REASON="USER_ACTION" REMOTE_IP="-" USER_AGENT="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36"] User logout: admin',
  }));

  let sampleUserData = {
    oktaId: "@kloudone.com",
    appsProvisioned: "100",
    bu: "GTO",
    location: "USA",
    City: "SA, India",
  };
  let permissionData = Array.from({ length: 10 }, (_, i) => ({
    ...samplePermissionData,
    id: i + 1,
    oktaId: userData && userData.length > 0 && userData[i].oktaId,
    time: generateRandomTime(),
    permission: "Yes",
    rules: "Show",
    City: "USA, USA",
  }));
  const ruleTypeChange = (value) => {
    setRuleType(value);
  };
  useEffect(() => {
    if (selectedBu) {
      setCurrentPage(1);
      fetchQueryUsers(1);
    }
  }, [selectedBu]);

  // useEffect(() => {
  //   fetchQueryUsers(1);
  // }, []);

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 className="title">Query Users</h1>
          <Button
            type="primary"
            onClick={() =>
              navigate(`/rules/addRules?ruleData=${JSON.stringify(attributes)}`)
            }
          >
            Add Rule
          </Button>
        </div>
        <Row>
          <Col span={24}>
            <Row className="search-box-container">
              <Col span={24}>
                <h5 className="search-label">Select App or endpoint</h5>
                <Select
                  className="search-component"
                  showSearch
                  defaultValue={ruleType}
                  onChange={ruleTypeChange}
                  onSearch={onSearch}
                >
                  <Option value="App">App</Option>
                  <Option value="Endpoint">Endpoint</Option>
                </Select>
              </Col>
              <Col span={24}>
                <Row>
                  {ruleType === "App" ? (
                    <Col span={8}>
                      <h5 className="search-label">App</h5>
                      <Select
                        className="search-component"
                        showSearch
                        placeholder="Search app name"
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={onAppChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={appList}
                        value={selectedApp}
                      />
                    </Col>
                  ) : (
                    <Col span={8}>
                      <h5 className="search-label">Endpoint</h5>
                      <Select
                        className="search-component"
                        showSearch
                        placeholder="Search app name"
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={endpoints}
                      />
                    </Col>
                  )}
                  <Col span={8}>
                    <h5 className="search-label">BU</h5>
                    <Select
                      className="search-component"
                      showSearch
                      placeholder="Search BU"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onBuChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={buList}
                      value={selectedBu}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                span={15}
                style={{ marginBottom: "2rem", paddingRight: "2rem" }}
              >
                <Collapse>
                  <Panel header="Advance Filter" key="1">
                    <GraphFilter
                      ruleType={ruleType}
                      // handleSearch={(e) => {
                      //   setGraphFilterValue(e);
                      //   setGraphFilter(false);
                      // }}
                      setAttributes={(e) => {
                        setAttributes(e);
                        fetchQueryUsers(1, e);
                      }}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            <h2 className="query-result-title">Results</h2>

            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Table
                      columns={userHeaders}
                      dataSource={queryUsersData}
                      pageSize={10}
                      loading={loading}
                      pagination={{
                        pageSize: 10,
                        total: totalCount,
                        onChange: (page) => {
                          setCurrentPage(page);
                          fetchQueryUsers(page);
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Drawer
        title="Version History"
        placement="right"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={500}
      >
        <RuleContainer />
      </Drawer>
      <Drawer
        className="github-commit-background"
        title="Permission Changes"
        placement="right"
        open={permissionVisible}
        onClose={() => setPermissionVisible(false)}
        width={500}
      >
        <Row className="commit-container">
          <Col className="version-history" span={24}>
            {/* Replace this part with your dynamic version history data */}
            {permission && permission?.metadata?.groups !== null ? (
              <>
                <Col span={24}>
                  <div className="github-commit-card github-commit-background">
                    <div className="commit-header">
                      <div className="commit-header">
                        {/* <p className="commit-info">Committed by {per.AuthorEmail}</p> */}
                      </div>
                    </div>
                    <div className="commit-details">
                      <p className="commit-message">
                        {permission?.metadata?.name}
                      </p>
                      <div className="commit-code">
                        <div
                          className="code-line"
                          style={{ marginTop: "1rem" }}
                        >
                          <p className="commit-info">{permission?.Filename}</p>
                          <pre className="code-snippet">
                            <pre>
                              {JSON.stringify(permission?.metadata, null, 2)}
                            </pre>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {inheritedPermission?.map((per) => (
                  <Col span={24}>
                    <div className="github-commit-card github-commit-background">
                      <div className="commit-header">
                        {/* <p className="commit-info">Committed by {per.AuthorEmail}</p> */}
                      </div>
                      <div className="commit-details">
                        <p className="commit-message">{per?.metadata.name}</p>
                        <div className="commit-code">
                          <div
                            className="code-line"
                            style={{ marginTop: "1rem" }}
                          >
                            <p className="commit-info">{per.Filename}</p>
                            <pre className="code-snippet">
                              <pre>
                                {JSON.stringify(per?.metadata, null, 2)}
                              </pre>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Drawer>
      <Drawer
        className="github-commit-background"
        title="Inherited Permissions Changes"
        placement="right"
        open={inheritedPermissionVisible}
        onClose={() => setInheritedPermissionVisible(false)}
        width={500}
      >
        <Row className="commit-container ">
          <Col className="version-history" span={24}>
            {/* Replace this part with your dynamic version history data */}
            {inheritedPermission && inheritedPermission.length > 0 ? (
              <>
                {inheritedPermission.map((per) => (
                  <Col span={24}>
                    <div className="github-commit-card ">
                      <div className="commit-header">
                        {/* <p className="commit-info">Committed by {per.AuthorEmail}</p> */}
                      </div>
                      <div className="commit-details">
                        <p className="commit-message">{per.metadata.name}</p>
                        <div className="commit-code">
                          <div
                            className="code-line"
                            style={{ marginTop: "1rem" }}
                          >
                            <p className="commit-info">{per.Filename}</p>
                            <pre className="code-snippet">
                              <pre>
                                {JSON.stringify(per?.metadata, null, 2)}
                              </pre>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            ) : null}
          </Col>
        </Row>
      </Drawer>

      <Modal
        title="Graph Query Filter"
        className="query-filter-modal"
        open={graphFilter}
        footer={null}
        onCancel={() => setGraphFilter(false)}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <GraphFilter
          ruleType={ruleType}
          handleSearch={(e) => {
            setGraphFilterValue(e);
            setGraphFilter(false);
          }}
        />
      </Modal>
    </Row>
  );
}

export default QueryUsers;
