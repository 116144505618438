import { useEffect, useState } from "react";
import { Col, Row, Select, Collapse, Input, Table, Drawer, Spin } from "antd";
import axios from "axios";

import {
  REACT_APP_API_SEGMENTS,
  REACT_APP_API_PERMISSION,
  REACT_APP_API_RULES,
  OKTA_TOKEN,
} from "../../constants";
import GraphFilter from "../query/graphFilter";
import { Link } from "react-router-dom";
import { logOut } from "../../common/logout";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const Users = () => {
  const [appName, setAppName] = useState("Google Workspace");
  const [isLoadingOktaUsers, setIsLoadingOktaUsers] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [ruleNames, setRuleNames] = useState();
  const [ruleDetail, setRuleDetail] = useState();
  const [filteredUsers, setFilteredUsers] = useState();
  const [inheritedPermissions, setInheritedPermissions] = useState();
  const [isInheritedDrawer, setIsInheritedDrawer] = useState(false);
  const [userMetaData, setUserMetaData] = useState();
  const [modifiedPermission, setModifiedPermission] = useState();
  const [isModifiedDrawer, setIsModifiedDrawer] = useState(false);
  const [isLoadingMetaData, setIsLoadingMetaData] = useState(false);
  const [selectedRule, setSelectedRule] = useState();

  // useeffects
  useEffect(() => {
    fetchOktaUsers();
    fetchRuleNames();
  }, []);

  useEffect(() => {
    fetchOktaUsers();
  }, [selectedRule]);

  // fetch calls
  const fetchOktaUsers = async () => {
    try {
      setIsLoadingOktaUsers(true);
      const res = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listOktaUsers`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      const tempUserObj = res?.data?.data?.map((user, index) => ({
        key: index + 1,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      }));

      setFilteredUsers(tempUserObj);
      setIsLoadingOktaUsers(false);
    } catch (error) {
      setIsLoadingOktaUsers(false);
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.log(error);
    }
  };

  const fetchPermissions = async (username) => {
    let payload = {
      username: username,
    };
    try {
      setIsLoadingPermissions(true);
      const res = await axios.post(
        `${REACT_APP_API_PERMISSION}/fetchPermissionHelper`,
        payload
      );
      setInheritedPermissions(res?.data?.data);
      setIsLoadingPermissions(false);
    } catch (error) {
      setIsLoadingPermissions(false);
      console.log(error);
    }
  };

  const fetchRuleNames = async () => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_RULES}/listRuleNames`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      setRuleNames(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRuleDetails = async (ruleName) => {
    let payload = {
      rule_name: ruleName,
    };
    if (!ruleName) {
      setRuleDetail("");
      return;
    }

    try {
      const res = await axios.post(
        `${REACT_APP_API_RULES}/getRuleDetails`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      const tempFilteredUserObj = res?.data?.users?.map((email, index) => ({
        key: index + 1,
        email: email,
      }));

      setRuleDetail(res?.data?.data.join(", "));
      setFilteredUsers(tempFilteredUserObj);
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.log(error);
    }
  };

  const fetchUserMetaData = async (username) => {
    let payload = {
      username: username,
    };
    try {
      setIsLoadingMetaData(true);
      const res = await axios.post(
        `${REACT_APP_API_RULES}/getUsersandRules`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.map((item, index) => {
        return {
          key: index + 1,
          rule_name: item.rules.rule_name,
          rule_conditions: item.rules.condition
            .map(
              (condition) =>
                `${condition.attribute} ${condition.operator} ${condition.value}`
            )
            .join(", "),
          permissions: item.data,
        };
      });

      setIsLoadingMetaData(false);
      setUserMetaData(tempData);
    } catch (error) {
      setIsLoadingMetaData(false);
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.log(error);
    }
  };

  // table headers
  const tableHeader = [
    {
      title: "Email",
      dataIndex: "email",
      render: (_, val) => (
        <Link to={`/user/profile?name=${val.name}&email=${val.email}`}>
          {val?.email}
        </Link>
      ),
    },
    {
      title: "Inherited Permission",
      render: (_, record) => (
        <>
          <a
            onClick={() => {
              setIsInheritedDrawer(true);
              fetchPermissions(record.email);
            }}
          >
            View
          </a>
        </>
      ),
    },
  ];

  const metaTableHeader = [
    {
      title: "Rule",
      dataIndex: "rule_name",
      key: "rule",
    },
    {
      title: "Rule meta data",
      key: "ruleMetaData",
      dataIndex: "rule_conditions",
    },
    {
      title: "Modified Permissions",
      key: "modifiedPermission",
      render: (_, record) => (
        <a
          onClick={() => {
            setIsModifiedDrawer(true);
            setModifiedPermission(record.permissions);
          }}
        >
          View
        </a>
      ),
    },
    {
      title: "Labels",
      dataIndex: "labels",
      render: (_, record) => <>Identity Risk</>,
    },
  ];

  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      fetchUserMetaData(record.email);
    }
  };

  return (
    <Row className="content-container">
      <Col span={24}>
        <Row>
          <h1 className="title">Users</h1>
        </Row>

        <Row style={{ marginBottom: "3rem" }}>
          <Col span={24}>
            <h5 className="search-label">Filter by:</h5>
            <Row gutter={20}>
              <Col span={6}>
                <h5 className="search-label">App: </h5>
                <Select
                  className="search-component"
                  defaultValue={appName}
                  onChange={(val) => {
                    setAppName(val);
                  }}
                >
                  <Option value="Google Workspace">Google Workspace</Option>
                </Select>
              </Col>
              <Col span={18}>
                <Row gutter={10}>
                  <Col span={8}>
                    <h5 className="search-label">Rules: </h5>
                    <Select
                      className="search-component"
                      placeholder="Select rule"
                      onChange={(value) => {
                        fetchRuleDetails(value);
                        setSelectedRule(value);
                      }}
                      allowClear
                      value={selectedRule}
                    >
                      {ruleNames?.map((rule, index) => (
                        <Option key={index} value={rule}>
                          {rule}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={16} style={{ marginTop: "3rem" }}>
                    <TextArea
                      disabled={!ruleDetail}
                      value={ruleDetail}
                      autoSize
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={16} style={{ marginBottom: "2rem" }}>
            <Collapse>
              <Panel header="Advance Filter">
                <GraphFilter />
              </Panel>
            </Collapse>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Table
              columns={tableHeader}
              dataSource={filteredUsers}
              loading={isLoadingOktaUsers}
              expandable={{
                expandedRowRender: (record) => (
                  <Row>
                    <Col span={24}>
                      <Table
                        style={{ marginLeft: "2.3rem" }}
                        columns={metaTableHeader}
                        dataSource={userMetaData}
                        pagination={false}
                        loading={isLoadingMetaData}
                      />
                    </Col>
                  </Row>
                ),
              }}
              onExpand={handleRowExpand}
            />
          </Col>
        </Row>
      </Col>

      <Drawer
        title="Inherited Permission"
        placement="right"
        open={isInheritedDrawer}
        onClose={() => setIsInheritedDrawer(false)}
        width={500}
      >
        {isLoadingPermissions ? (
          <p style={{ textAlign: "center", paddingTop: "2rem" }}>
            <Spin />
          </p>
        ) : (
          <Row>
            <Col span={24}>
              <Col span={24}>
                <div className="github-commit-card github-commit-background">
                  <div className="commit-details">
                    <div className="commit-code">
                      <div
                        className="code-line"
                        style={{
                          marginTop: "1rem",
                          marginLeft: "0.2rem",
                        }}
                      >
                        <pre className="code-snippet">
                          <pre>
                            {JSON.stringify(inheritedPermissions, null, 2)}
                          </pre>
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        )}
      </Drawer>

      <Drawer
        title="Modified Permission"
        placement="right"
        open={isModifiedDrawer}
        onClose={() => setIsModifiedDrawer(false)}
        width={500}
      >
        <Row>
          <Col span={24}>
            <Col span={24}>
              <div className="github-commit-card github-commit-background">
                <div className="commit-details">
                  <div className="commit-code">
                    <div
                      className="code-line"
                      style={{
                        marginTop: "1rem",
                        marginLeft: "0.2rem",
                      }}
                    >
                      <pre className="code-snippet">
                        <pre>{JSON.stringify(modifiedPermission, null, 2)}</pre>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Drawer>
    </Row>
  );
};

export default Users;
