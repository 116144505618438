import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import "../../styles/rules.scss"; // Import your custom CSS for styling
import moment from "moment-timezone";
import axios from "axios";
import{REACT_APP_API_SEGMENTS, REACT_APP_API_RULES} from '../../constants';

const { Text } = Typography;

const codes = [
  {
    Effect: "Allow",
    Action: [
      "iam:ListUsers",
      "iam:ListGroups",
      "iam:GetGroups",
      "iam:CreateUsers",
      "iam:UpdateUsers",
      "iam:DeleteUsers",
    ],
  },
  {
    Effect: "Allow",
    Action: [
      "iam:ListUsers",
      "iam:ListGroups",
      "iam:GetGroups",
      "iam:CreateUsers",
      "iam:UpdateUsers",
      "iam:DeleteUsers",
    ],
  },
];

const RuleContainer = () => {
  const [rules, setRules] = useState([
    {
      id: 1,
      rule_name: "MuthuEPMUser",
      status: "Created",
      created_by: "Inam",
      created_at: "2023-08-11T13:02:46.032065Z",
      updated_at: "2023-08-11T13:02:46.032065Z",
      rules_condition: [
        {
          attribute: "username",
          operator: "=",
          value: "muthu",
        },
        {
          attribute: "host",
          operator: "=",
          value: "122.178.201.106",
        },
        {
          attribute: "permission",
          operator: "=",
          value: "allow",
        },
      ],
    },
  ]);
  const fetchData = async () => {
    let data={
      pageNo: 1,
      pageSize: 1000,
      filter: {
        userName: "",
        status: "",
        appName: "",
      },
    }
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/ListRules`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );

      setRules(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  },[]);
  return (
    <Row className="commit-container">
      <Col span={24} className="version-history">
        {/* <h3 className='version-history-title' style={{ marginBottom: '1rem' }}>Version History</h3> */}
        <Col span={24}>
          {rules.map((rule, index) => (
            <div key={index} className="github-commit-card">
              <div className="commit-header">
                <p className="commit-info">
                  Last updated by {rule.created_by} on{" "}
                  {moment(rule.updated_at)
                    .tz("America/Los_Angeles")
                    .format("MMM DD, YYYY")}
                </p>
              </div>
              <div className="commit-details">
                <p className="commit-message">{rule.rule_name}</p>
                <div className="commit-code">
                  <pre className="code-snippet">
                    {rule.rules_condition &&
                      rule.rules_condition.map(
                        (condition, conditionIndex) =>
                          condition.attribute !== "permission" && (
                            <div key={conditionIndex} className="code-line">
                              If{" "}
                              <span className="code-keyword">
                                {condition.attribute}
                              </span>
                              <span className="code-operator">
                                {condition.operator}
                              </span>
                              <span className="code-value">
                                {condition.value}
                              </span>
                            </div>
                          )
                      )}
                    <div className="code-line">
                      Then permission{" "}
                      <span
                        className={`code-${
                          rule.permission ? "allowed" : "denied"
                        }`}
                      >
                        {rule.permission ? "allowed" : "denied"}
                      </span>
                    </div>
                  </pre>
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Col>
    </Row>
  );
};

export default RuleContainer;
