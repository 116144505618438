// import "./styles/index.scss";
import {
  Col,
  Row,
  Tabs,
  Select,
  Table,
  Tag,
  Drawer,
  Collapse,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import {
  REACT_APP_API_SEGMENTS,
  REACT_APP_API_RULES,
  REACT_APP_API_PERMISSION,
  OKTA_TOKEN,
} from "../../constants";
import GraphFilter from "../query/graphFilter";
import axios from "axios";
import { logOut } from "../../common/logout";
const { Panel } = Collapse;

const { TabPane } = Tabs;
const { Option } = Select;

function Segment() {
  const [rule, setRule] = useState();
  const [selectedApp, setSelectedApp] = useState("");
  const [endpoints, setEndpoints] = useState();
  const [staticData, setStaticData] = useState([]);
  const [appList, setApplist] = useState();
  const [buList, setBuList] = useState();
  const [ruleType, setRuleType] = useState("App");
  const [selectedBu, setSelectedBu] = useState("");
  const [attributes, setAttributes] = useState();
  const [commits, setCommits] = useState();
  const [commitVisible, setCommitVisible] = useState(false);
  const [permissionVisible, setPermissionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCommit, setLoadingCommit] = useState(false);
  const navigate = useNavigate();

  const fetchCommit = async (val) => {
    setLoadingCommit(true);
    try {
      const response = await axios.post(
        `${REACT_APP_API_PERMISSION}/getFileChanges`,
        { foldername: val.segmentName },
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      setCommits(tempData);
      setLoadingCommit(false);
    } catch (error) {
      setLoadingCommit(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPermissions = async (val) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_PERMISSION}/fetchBranch`,
        { foldername: [val.segmentName] },
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      // tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setRule(tempData);
      setPermissionVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEndpoints = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_RULES}/listofendpoints`,
        {},
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      let tempData = response.data.data;
      tempData = tempData.map((ip) => ({ ["key"]: ip, value: ip }));
      setEndpoints(tempData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApps = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listappnames`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );
      let tempData = response.data.data;

      tempData = tempData.map((app) => ({
        ["key"]: app.appName,
        value: app.appName,
      }));
      // tempData.push({key:"all", value:"All"})
      setApplist(tempData);
      setSelectedApp(tempData[0].value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEndpoints();

    fetchApps();
    fetchBu();
    fetchStaticData();
  }, []);

  const fetchData = async (e) => {
    console.log(attributes, "Asif");
    let data;
    let filters = {};
    if (e) {
      data = {
        pageNo: 1,
        pageSize: 1000,
        filters: {},
      };

      e &&
        e.length > 0 &&
        e.forEach((item) => {
          filters[item.key] = item.value;
        });
      data.filters = filters;
    } else {
      data = {
        pageNo: 1,
        pageSize: 1000,
        filters: {},
      };
      attributes &&
        attributes.length > 0 &&
        attributes.forEach((item) => {
          filters[item.key] = item.value;
        });
      data.filters = filters;
    }

    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/getsegmenstfromgraphwithquery`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );

      setStaticData(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchBu = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/listbu`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": OKTA_TOKEN(),
          },
        }
      );

      let tempData = response.data.data;

      tempData = tempData.map((app) => ({
        ["key"]: app.bu,
        value: app.bu,
      }));
      setBuList(tempData);
      setSelectedBu(tempData[0].value);
    } catch (error) {
      if (
        error.response.data === "Unauthorized" ||
        error.response?.status === 401
      ) {
        logOut();
      }
      console.error("Error fetching data:", error);
    }
  };

  const fetchStaticData = async (e) => {
    setIsLoading(true);
    let data;
    let filters = {};
    if (e) {
      data = {
        pageNo: 1,
        pageSize: 1000,
        filters: {},
      };

      e &&
        e.length > 0 &&
        e.forEach((item) => {
          filters[item.key] = item.value;
        });
      data.filters = filters;
    } else {
      data = {
        pageNo: 1,
        pageSize: 1000,
        filters: {},
      };
      attributes &&
        attributes.length > 0 &&
        attributes.forEach((item) => {
          filters[item.key] = item.value;
        });
      data.filters = filters;
    }
    if (ruleType) {
      data.filters["segmentType"] = "";
    }
    if (selectedBu) {
      data.filters["bu"] = selectedBu;
    }
    if (selectedApp) {
      data.filters["appname"] = selectedApp;
    }
    try {
      const response = await axios.post(
        `${REACT_APP_API_SEGMENTS}/segments/getsegmentswithitattributes`,
        data,
        {
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      setStaticData(response.data.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const onAppChange = (e) => {
    setSelectedApp(e.value);
    fetchStaticData(e.value);
  };

  const onChange = () => {};
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onBuChange = (e) => {
    setSelectedBu(e.value);
    // fetchData(null, e.value);

    fetchStaticData();
  };

  const myTreeData = [
    {
      name: "client_geographical_context_country: United States",
      children: [
        {
          name: "client_geographical_context_city: San Diego",
          children: [
            {
              name: "vpn: true",
            },
            {
              name: "outcome_result: SUCCESS",
            },
          ],
        },
      ],
    },
  ];

  const columns = [
    {
      title: "Segment Id",
      dataIndex: "segmentId",
      key: "segmentId",
    },
    {
      title: "Segment Name",
      dataIndex: "segmentName",
      key: "segmentName",
    },
    {
      title: "App",
      dataIndex: "appName",
      key: "appName",
    },
    {
      title: "BU",
      dataIndex: "bu",
      key: "bu",
    },
    {
      title: "Segment Type",
      dataIndex: "segmentType",
      key: "segmentType",
    },
    {
      title: "No Of Users",
      dataIndex: "userCount",
      key: "userCount",

      render: (_, record) => (
        <Link
          disabled={record.userCount == 0 ? true : false}
          onClick={() => navigate("/query/users")}
        >
          {record.userCount}
        </Link>
      ),
    },
    {
      title: "Segment Query",
      key: "segmentQuery",
      dataIndex: "segmentQuery",
      render: (_, record) => (
        <>
          {record &&
            record.attributes.length > 0 &&
            record.attributes.map((tag, index) => (
              <Tag color="geekblue" key={index}>
                {`${tag.attributeName}${tag.matchPattern} ${tag.attributeValue}`}
              </Tag>
            ))}
        </>
      ),
    },
    {
      title: "Inherited Permissions",
      key: "view",
      dataIndex: "view",
      render: (_, record) => (
        <>
          <a
            onClick={() => {
              fetchPermissions(record);
            }}
          >
            View
          </a>
        </>
      ),
    },
    {
      title: "Permissions History",
      key: "view",
      dataIndex: "view",
      render: (_, record) => (
        <>
          <a
            onClick={() => {
              fetchCommit(record);
              setCommitVisible(true);
            }}
          >
            View
          </a>
        </>
      ),
    },
  ];

  const locationList = [
    { key: 0, value: "United States - San Jose, California" },
    { key: 1, value: "Singapore" },
    { key: 2, value: "India - Bengaluru, Karnataka" },
    { key: 3, value: "Israel - Herzliya Pituach" },
  ];

  const ruleTypeChange = (value) => {
    setRuleType(value);
    // fetchData("", "");
    fetchStaticData();
  };

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <h2 className="title">Segments</h2>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h5 className="search-label">Select App or endpoint</h5>
                <Select
                  className="search-component"
                  showSearch
                  defaultValue={ruleType}
                  onChange={ruleTypeChange}
                  onSearch={onSearch}
                >
                  <Option value="App">App</Option>
                  <Option value="Endpoint">Endpoint</Option>
                </Select>
              </Col>
              <Col span={24}>
                <Row className="search-box-container">
                  {ruleType === "App" ? (
                    <Col span={8}>
                      <h5 className="search-label">App</h5>
                      <Select
                        className="search-component"
                        showSearch
                        placeholder="Search app name"
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={onAppChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={appList}
                        value={selectedApp}
                      />
                    </Col>
                  ) : (
                    <Col span={8} style={{ marginBottom: "2rem" }}>
                      <h5 className="search-label">Endpoint</h5>
                      <Select
                        className="search-component"
                        showSearch
                        placeholder="Search endpoint"
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={endpoints}
                      />
                    </Col>
                  )}
                  <Col span={8}>
                    <h5 className="search-label">BU</h5>
                    <Select
                      className="search-component"
                      showSearch
                      placeholder="Search BU"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onBuChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={buList}
                      value={selectedBu}
                    />
                  </Col>
                  <Col span={8}>
                    <h5 className="search-label">Location</h5>
                    <Select
                      className="search-component"
                      showSearch
                      placeholder="Search location"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={locationList}
                      value={locationList[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={15}
                    style={{ marginBottom: "2rem", paddingRight: "2rem" }}
                  >
                    <Collapse>
                      <Panel header="Advance Filter" key="1">
                        <GraphFilter
                          ruleType={ruleType}
                          setAttributes={(e) => {
                            setAttributes(e);
                            fetchStaticData(e);
                          }}
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={staticData}
                      loading={isLoading}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Drawer
        title="Permission Changes"
        placement="right"
        open={permissionVisible}
        onClose={() => setPermissionVisible(false)}
        width={500}
      >
        <Row className="commit-container">
          <Col className="version-history" span={24}>
            {/* Replace this part with your dynamic version history data */}
            {rule ? (
              <>
                <Col span={24}>
                  <div className="github-commit-card">
                    <div className="commit-header">
                      <p className="commit-info">
                        Last updated by {rule?.created_by} on{" "}
                        {moment(rule?.updated_at)
                          .tz("America/Los_Angeles")
                          .format("MMM DD, YYYY")}
                      </p>
                    </div>
                    {rule?.map((ruleData) => (
                      <div className="commit-details">
                        {console.log(rule, "he")}
                        <p className="commit-message">
                          {ruleData?.metadata?.name}
                        </p>
                        <div className="commit-code">
                          <div
                            className="code-line"
                            style={{ marginTop: "1rem" }}
                          >
                            <pre className="code-snippet">
                              <pre>
                                {JSON.stringify(ruleData?.metadata, null, 2)}
                              </pre>
                            </pre>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            ) : null}
          </Col>
        </Row>
      </Drawer>
      <Drawer
        title="Permission Changes"
        placement="right"
        open={commitVisible}
        onClose={() => setCommitVisible(false)}
        width={500}
      >
        {loadingCommit && commits === undefined && (
          <div style={{ textAlign: "center", marginTop: "7rem" }}>
            <Spin />
          </div>
        )}

        <Row className="commit-container">
          <Col className="version-history" span={24}>
            {/* Replace this part with your dynamic version history data */}
            {commits && commits.length > 0 ? (
              <>
                {commits.map((commit, index) => (
                  <Col span={24}>
                    <div className="github-commit-card">
                      <div className="commit-header">
                        <p className="commit-info">
                          Committed by {commit.AuthorEmail} on{" "}
                          {moment(commit.CommitDate)
                            .tz("America/Los_Angeles")
                            .format("MMM DD, YYYY")}
                        </p>
                      </div>
                      <div className="commit-details">
                        <p className="commit-message">{commit.CommitMessage}</p>
                        <div className="commit-code">
                          {commit.FilesChanged &&
                            commit.FilesChanged.map((file, conditionIndex) => (
                              <>
                                {file.Rules ? (
                                  <div
                                    className="commit-code"
                                    style={{ border: "none" }}
                                  >
                                    <h4 style={{ color: "green" }}>
                                      {file.Filename}
                                    </h4>
                                    {file.Rules &&
                                      file.Rules.map(
                                        (condition, conditionIndex) =>
                                          condition.attribute !==
                                            "permission" && (
                                            <>
                                              <pre className="code-snippet">
                                                <div
                                                  key={conditionIndex}
                                                  className="code-line"
                                                >
                                                  If{" "}
                                                  <span className="code-keyword">
                                                    {condition.attribute}
                                                  </span>
                                                  <span className="code-operator">
                                                    {condition.operator}
                                                  </span>
                                                  <span className="code-value">
                                                    {condition.value}
                                                  </span>
                                                </div>
                                              </pre>
                                            </>
                                          )
                                      )}
                                  </div>
                                ) : (
                                  <div
                                    className="code-line"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    <h4 style={{ color: "green" }}>
                                      {file.Filename}
                                    </h4>
                                    <pre className="code-snippet">
                                      <pre>
                                        {file.Permissions &&
                                          JSON.stringify(
                                            file.Permissions.metadata,
                                            null,
                                            2
                                          )}
                                      </pre>
                                    </pre>
                                  </div>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            ) : null}
          </Col>
        </Row>
      </Drawer>
    </Row>
  );
}

export default Segment;
